import { store } from 'Store/store'
import { Flavor, Categories, CategoryTypes } from 'Constants/storeTypes';
import { AppService } from 'AppServices/appService';
import { UserRoles } from 'Constants/userRoles';

export class BrandPortalHelper {
  constructor(){
    this.appService = new AppService();
  }
  async setupRoimaticConfig(){
    let roimaticConfiguration = {
      category: CategoryTypes.find(category => category.id == Categories.BrandPortal).category,
      flavour: Flavor.BrandPortal,
      isBusinessSetup: true,
      onboardingState: 5,
      isSelfFunded: true
    }

    await store.dispatch('setUserDivisions');
    await store.dispatch('setRoimaticConfiguration', roimaticConfiguration);
    return roimaticConfiguration;
  }

  async asyncForeach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  async getPortalData(asyncbpCallback, businessProfileId, filter = null){
    let user = store.state.auth.user;
    let allData = [];
    let isAccountManager = false;
    let isBrandManager = false;
    if (user.roleIds) {
      isAccountManager = user.roleIds.some(roleId => roleId == UserRoles.AccountManager);
      isBrandManager = user.roleIds.some(roleId => roleId == UserRoles.BrandManager);
    }

    if(!isAccountManager && !isBrandManager){
      // if the user is neither an account manager nor a brand manager then return empty data
      return allData;
    }
    else if(!isAccountManager && isBrandManager){
      // if the user is a brand manager then filter by divisions
      let divisions = store.state.roimaticConfiguration.userDivisions;
      if(!divisions || divisions.length == 0){
        // if there are no divisions for the user, then return empty list
        return allData;
      }
      filter = filter ? filter : {};
      filter.divisionNames = divisions.map(division => division.divisionName).join(',');
    }
    // if none of the above 'if' conditions match then the user would be an 'Account Manager'.
    // Therefore in this case, we will be fetching data without applying the divisions filters

    let filteredProfiles = user.businessProfiles
      .filter(businessProfile => businessProfile.id !== -1
        && (businessProfileId == -1 || businessProfileId == businessProfile.id));
    // if businessProfileId is provided in parameter then fetch data for only that profile
    await this.asyncForeach(filteredProfiles, async businessProfile => {
      if (businessProfile.currencyId == 0 || businessProfile.currencyId == null)
      {
        let bp = await this.appService.getBusinessProfileById(businessProfile.id);
        businessProfile.currencyId = bp.currencyId;
      }
      let getData = asyncbpCallback(businessProfile.id, filter);
      let getCurrency = this.appService.getCurrencyInfo(businessProfile.currencyId, businessProfile.id);
      let bpData = await getData;
      let currencyInfo = await getCurrency;
      bpData.forEach(data => {
        data.currencyCode = currencyInfo.code;
      });
      allData.push(...(await bpData));
    })
    return allData;
  }
}