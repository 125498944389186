/**
 * Initializes ffmpeg in-browser - https://github.com/ffmpegwasm/ffmpeg.wasm#usage
 * This method is called once when download video is invoked
 * @returns ffmpeg instance
 */
// AY TODO: Would be ideal to move this out to "Encoder" functionality

var ffmpegLogs = [];
var setLogging = false;

export async function initFFmpeg() {
  // FFmpeg instance is globally added using script tag in the index.html page
  // https://github.com/ffmpegwasm/ffmpeg.wasm#installation > "Browser"

  const { createFFmpeg } = FFmpeg;
  var ffmpeg = createFFmpeg({ log: false });
  // eslint-disable-next-line
  console.log('Loading ffmpeg-core.js');
  if (!ffmpeg.isLoaded()) {
    await ffmpeg.load();
  }
  ffmpeg.setLogger(({ type, message }) => {
    if (!setLogging) {
      return;
    }
    ffmpegLogs.push(message);
  });
  return ffmpeg;
}

/**
 * Closes and cleans up ffmpeg in-browser - https://github.com/ffmpegwasm/ffmpeg.wasm/blob/master/docs/api.md#ffmpeg-exit
 *
 * @param {*} ffmpeg - the ffmpeg instance
 * @deprecated - this method is no longer used as it causes OOM errors on repeated init+exit
 */
export function exitFfmpeg(ffmpeg) {
  // eslint-disable-next-line
    console.log('Exiting ffmpeg');
  try {
    // added this in try to avoid ffmpeg exited with status 1 error.
    ffmpeg.exit(0);
  }
  catch (e) {
    ffmpeg = null;
  }
}

export const setFfmpegLogging = (value) => {
  setLogging = value;
}

export const getFfmpegLogs = () => {
  return ffmpegLogs;
}

export const clearFfmpegLogs = () => {
  ffmpegLogs = [];
}