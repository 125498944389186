const CbRenderer = () => import('Features/cbRenderer/Main');

export default {
  name: 'cbRenderer',
  path: 'cbRenderer',
  component: CbRenderer,
  meta: {
    requiresAuth: false,
    title: 'message.cbRenderer.title'
  }
}
