const BriefPlan = () => import(/* webpackChunkName: "briefPlan" */ 'Features/briefPlan/Main')

const briefPlanRoute = {
  name: 'briefPlan',
  path: 'briefs/:briefId/plans',
  component: BriefPlan,
  meta: {
    requiresAuth: true,
    title: 'message.briefPlanMessages.briefPlan',
    breadcrumb: 'message.briefPlanMessages.briefPlan'
  }
};

export const BriefPlanRoute = briefPlanRoute;