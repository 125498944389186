import { initialize } from './api';
import axios from 'axios';

const axiosInstance = axios.create();

export class GoogleTextToSpeechService {
  constructor(apiPayload) {
    initialize(axiosInstance, apiPayload.accessToken, apiPayload.baseURL);
  }

  async getSpeechForText(ttsConfiguration, businessProfileId) {
    let requestBody = {
      audioConfig: {
        audioEncoding: 'MP3',
        pitch: ttsConfiguration.pitch,
        speakingRate: ttsConfiguration.speakingRate
      },
      input: {
        text: ttsConfiguration.text
      },
      voice: ttsConfiguration.voice
    }
    let response = await axiosInstance.post(`/businessProfiles/${businessProfileId}/google/textToSpeech`, requestBody);
    return response.data.data;
  }

  async getVoices (businessProfileId) {
    let response = await axiosInstance.get(`/businessProfiles/${businessProfileId}/google/voices`);
    return response.data.data;
  }
}