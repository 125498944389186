
// messages
import messages from './messages';

//number formats
import numberFormats from '@/numberFormats'

import Vue from 'vue'

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
  numberFormats
})