export const gridLayoutPositions = {
  topLeft: 1,
  topCentre: 2,
  topRight: 3,
  middleLeft: 4,
  middleCentre: 5,
  middleRight: 6,
  bottomLeft: 7,
  bottomCentre: 8,
  bottomRight: 9
}

export const topProximityFactor = 0.01;
export const bottomProximityFactor = 0.99;
export const leftProximityFactor = 0.01;
export const rightProximityFactor = 0.98;

export const gridLayoutTypes = {
  Square: 1,
  Rectangle: 2,
  Horizontal: 3,
  Vertical: 4
}

export const directions = {
  left: 1,
  top: 2,
  right: 3,
  bottom: 4
}

export const keyDirections = {
  left: 'ArrowLeft',
  top: 'ArrowUp',
  right: 'ArrowRight',
  bottom: 'ArrowDown'
}