const TWEEN = require('@tweenjs/tween.js');
import { animateConstants, typeNames, TWEEN_START_VALUE, TWEEN_END_VALUE, MIN_TWEEN_DURATION, currentAnimationConfigValue } from '../constants/animation';
import { standardCanvasWidth } from '../constants/commonData';
import { getRelativeLength } from './tweenUtils';
import { shineAnimationDirection, circularShineAnimationDirection } from '../constants/animation';
import { layers as layerTypes } from '../constants/layers';

/**
 * A transition item's definition.
 * @typedef {Object} TransitionItem
 * @property {string} name - the name of the transistion. Defined in {typeNames}.
 * @property {function} getTweens - a function that generates the Tweens for this transistion type.
 */
/**
 * The supported transitions for start and end animation. Used in animationMutations.startAnimation and AnimationPreview.vue
 * @type {TransitionItem[]}
 */

export const createGradient = (target, originalColor, gradientColor, coords, shineWidth) => {
  return new fabric.Gradient({
    type: 'linear',
    colorStops: [
      { color: originalColor, offset: 0 },
      { color: originalColor, offset: Math.min(Math.max(-shineWidth, 0), 1) },
      { color: gradientColor, offset: 0.0 },
      { color: originalColor, offset: Math.max(Math.min(shineWidth, 1), 0) },
      { color: originalColor, offset: 1 }
    ],
    coords: coords,
    gradientUnits: 'pixels'
  });
}

export const startAndEndTransitions = [
  {
    name: typeNames.fade,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const fadeStart = configs.fadeStart == currentAnimationConfigValue ? updatedTarget.opacity : (Math.ceil(configs.fadeStart) / 100) ;
      const fadeEnd = configs.fadeEnd == currentAnimationConfigValue ? updatedTarget.opacity : (Math.ceil(configs.fadeEnd) / 100);
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () { Object.assign(target, { opacity: fadeStart }); })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ opacity: fadeEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  },
  {
    name: typeNames.shine,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs, timelineTime, layerType) => {
      let shineTarget = target;
      if (layerType === layerTypes.button) {
        // For button, shine effect is applied to the rect object of the fabric group
        shineTarget = target.getObjects().find(obj => obj.type === 'rect');
      }
      const shineWidth = (configs.strokeWidth / 100) / 2;
      let originalColor;
      if (typeof shineTarget.fill === 'string') {
        originalColor = shineTarget.fill;
      } else if (shineTarget.fill.type === 'linear') {
        originalColor = shineTarget.fill.colorStops[0].color;
        shineTarget.fill = shineTarget.fill.colorStops[0].color;
        if (shineTarget.innerFill) {
          originalColor = shineTarget.innerFill;
        }
      } else {
        originalColor = 'rgba(255, 0, 0, 1)';
      }
      const gradientColor = `rgba(${configs.color.rgba.r}, ${configs.color.rgba.g}, ${configs.color.rgba.b}, ${configs.color.rgba.a})`;
      let coords;
      switch (configs.direction) {
        case shineAnimationDirection.FORWARD:
          coords = { x1: 0, y1: 0, x2: shineTarget.width, y2: 0 };
          break;
        case shineAnimationDirection.BACKWARD:
          coords = { x1: shineTarget.width, y1: 0, x2: 0, y2: 0 };
          break;
        case shineAnimationDirection.DOWN:
          coords = { x1: 0, y1: 0, x2: 0, y2: shineTarget.height };
          break;
        case shineAnimationDirection.UP:
          coords = { x1: 0, y1: shineTarget.height, x2: 0, y2: 0 };
          break;
        case shineAnimationDirection.TOP_LEFT_TO_BOTTOM_RIGHT:
          coords = { x1: 0, y1: 0, x2: shineTarget.width, y2: shineTarget.height };
          break;
        case shineAnimationDirection.TOP_RIGHT_TO_BOTTOM_LEFT:
          coords = { x1: shineTarget.width, y1: 0, x2: 0, y2: shineTarget.height };
          break;
        case shineAnimationDirection.BOTTOM_LEFT_TO_TOP_RIGHT:
          coords = { x1: 0, y1: shineTarget.height, x2: shineTarget.width, y2: 0 };
          break;
        case shineAnimationDirection.BOTTOM_RIGHT_TO_TOP_LEFT:
          coords = { x1: shineTarget.width, y1: shineTarget.height, x2: 0, y2: 0 };
          break;
        default:
          coords = { x1: 0, y1: 0, x2: shineTarget.width, y2: shineTarget.height };
          break;
      }

      const gradient = createGradient(shineTarget, originalColor, gradientColor, coords, shineWidth);

      // while at the time of pause and play, the timelineTime is not null and shine effect is applied to the button, then we need to store the original shine color
      if (timelineTime && layerType === layerTypes.button) {
        shineTarget.originalFill ||= shineTarget.get('fill');
      }

      // Define the tween for the shine effect
      const o = { value: TWEEN_START_VALUE };
      const repeatCount = configs.repeat || 1;
      const cycleDuration = duration / repeatCount;

      const tweens = [];

      // Start tween to initiate the gradient fill
      tweens.push(
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            shineTarget.set('fill', gradient);
            target.set('opacity', updatedTarget.opacity);
          })
          .delay(startDelay)
      );

      // Main tween to move the shine effect
      for (let i = 0; i < repeatCount; i++) {
        tweens.push(
          new TWEEN.Tween({ offset: -shineWidth }, timeline)
            .to({ offset: 1 + shineWidth }, cycleDuration)
            .onUpdate(({ offset }) => {
              gradient.colorStops[1].offset = Math.min(Math.max(offset - shineWidth, 0), 1);
              gradient.colorStops[2].offset = Math.min(Math.max(offset, 0), 1);
              gradient.colorStops[3].offset = Math.min(Math.max(offset + shineWidth, 0), 1);
              shineTarget.set('fill', gradient);
            })
            .onComplete(() => {
              if (i === repeatCount - 1) {
                shineTarget.set('fill', originalColor); // Reset after the last repetition
              }
            })
        );
      }

      return tweens;
    }
  },
  {
    name: typeNames.circularShine,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs, timelineTime, layerType) => {
      let shineTarget = target;
      if (layerType === layerTypes.button) {
        shineTarget = target.getObjects().find(obj => obj.type === 'rect');
      }
      let originalColor;
      if (typeof shineTarget.fill === 'string') {
        originalColor = shineTarget.fill;
      } else if (shineTarget.fill.type === 'linear') {
        originalColor = shineTarget.fill.colorStops[0].color;
        shineTarget.fill = shineTarget.fill.colorStops[0].color;
        if (shineTarget.innerFill) {
          originalColor = shineTarget.innerFill;
        }
      } else {
        originalColor = 'rgba(255, 0, 0, 1)';
      }
      const gradientColor = `rgba(${configs.color.rgba.r}, ${configs.color.rgba.g}, ${configs.color.rgba.b}, ${configs.color.rgba.a})`;

      const maxRadius = Math.sqrt(Math.pow(shineTarget.width / 2, 2) + Math.pow(shineTarget.height / 2, 2));
      const shineWidth = (configs.strokeWidth / 100);
      let initialRadius = 0;
      let finalRadius = maxRadius;

      const gradient = new fabric.Gradient({
        type: 'radial',
        coords: {
          r1: initialRadius,
          r2: finalRadius,
          x1: shineTarget.width / 2,
          y1: shineTarget.height / 2,
          x2: shineTarget.width / 2,
          y2: shineTarget.height / 2
        },
        colorStops: [
          { offset: 0, color: originalColor },
          { offset: Math.min(Math.max(1 - shineWidth, 0), 1), color: originalColor },
          { offset: Math.min(Math.max(1 - (shineWidth / 2), 0), 1), color: gradientColor },
          { offset: 1, color: originalColor }
        ]
      });

      const o = { value: TWEEN_START_VALUE };

      // handling the repeat count
      const repeatCount = configs.repeat || 1;
      const cycleDuration = duration / repeatCount;

      const tweens = [];

      // Start tween to initiate the gradient fill
      tweens.push(
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            shineTarget.set('fill', gradient);
            target.set('opacity', updatedTarget.opacity);
          })
          .delay(startDelay)
      );

      // Main tween for the circular shine
      for (let i = 0; i < repeatCount; i++) {
        tweens.push(
          new TWEEN.Tween({ radius: initialRadius }, timeline)
            .to({ radius: finalRadius }, cycleDuration)
            .onUpdate(({ radius }) => {
              if (configs.direction === circularShineAnimationDirection.CENTER_TO_END) {
                gradient.coords.r2 = radius;
              } else if (configs.direction === circularShineAnimationDirection.END_TO_CENTER) {
                gradient.coords.r2 = maxRadius - radius;
              }
            })
            .onComplete(() => {
              if (i === repeatCount - 1) {
                shineTarget.set('fill', originalColor);
              }
            })
        );
      }

      return tweens;
    }
  },
  {
    name: typeNames.slideHorizontal,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const slideStart = configs.slideStart == currentAnimationConfigValue ? updatedTarget.left : (Math.ceil(configs.slideStart) / 100) * canvas.width;
      const slideEnd = configs.slideEnd == currentAnimationConfigValue ? updatedTarget.left : (Math.ceil(configs.slideEnd) / 100) * canvas.width;
      const newTweens = [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            Object.assign(target, {
              left: slideStart,
              opacity: updatedTarget.opacity
            });
          })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ left: slideEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
      return newTweens;
    }
  },
  {
    name: typeNames.slideVertical,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const slideStart = configs.slideStart == currentAnimationConfigValue ? updatedTarget.top : (Math.ceil(configs.slideStart) / 100) * canvas.height;
      const slideEnd = configs.slideEnd == currentAnimationConfigValue ? updatedTarget.top : (Math.ceil(configs.slideEnd) / 100) * canvas.height;
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            Object.assign(target, {
              top: slideStart,
              opacity: updatedTarget.opacity
            });
          })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ top: slideEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  },
  {
    name: typeNames.fadeSlideHorizontal,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const slideStart = configs.slideStart == currentAnimationConfigValue ? updatedTarget.left : (Math.ceil(configs.slideStart) / 100) * canvas.width;
      const slideEnd = configs.slideEnd == currentAnimationConfigValue ? updatedTarget.left : (Math.ceil(configs.slideEnd) / 100) * canvas.width;
      const fadeStart = configs.fadeStart == currentAnimationConfigValue ? updatedTarget.opacity : (Math.ceil(configs.fadeStart) / 100) ;
      const fadeEnd = configs.fadeEnd == currentAnimationConfigValue ? updatedTarget.opacity : (Math.ceil(configs.fadeEnd) / 100);
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            Object.assign(target, {
              left: slideStart,
              opacity: fadeStart
            });
          })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ left: slideEnd, opacity: fadeEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  },
  {
    name: typeNames.fadeSlideVertical,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const slideStart = configs.slideStart == currentAnimationConfigValue ? updatedTarget.top : (Math.ceil(configs.slideStart) / 100) * canvas.height;
      const slideEnd = configs.slideEnd == currentAnimationConfigValue ? updatedTarget.top : (Math.ceil(configs.slideEnd) / 100) * canvas.height;
      const fadeStart = configs.fadeStart == currentAnimationConfigValue ? updatedTarget.opacity : (Math.ceil(configs.fadeStart) / 100) ;
      const fadeEnd = configs.fadeEnd == currentAnimationConfigValue ? updatedTarget.opacity : (Math.ceil(configs.fadeEnd) / 100);
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            Object.assign(target, {
              top: slideStart,
              opacity: fadeStart
            });
          })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ top: slideEnd, opacity: fadeEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  },
  {
    name: typeNames.rotate,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const angleStart = configs.angleStart == currentAnimationConfigValue ? updatedTarget.angle : Math.ceil(configs.angleStart);
      const angleEnd = configs.angleEnd == currentAnimationConfigValue ? updatedTarget.angle : Math.ceil(configs.angleEnd);
      const rotations = configs.rotations;
      let rotationDuration = duration / rotations;
      configs.retrace && (rotationDuration /= 2);
      const tweens = [];
      for(let i = 0; i < rotations; i++) {
        tweens.push(
          new TWEEN.Tween(o, timeline)
            .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
            .onStart(function() {
              Object.assign(target,
                {
                  angle: angleStart,
                  opacity: updatedTarget.opacity
                });
            })
            .delay(startDelay)
        )
        tweens.push(
          new TWEEN.Tween(target, timeline)
            .to({ angle: angleEnd }, rotationDuration)
            .easing(TWEEN.Easing[easing.ease][easing.type])
        )
        if(configs?.retrace) {
          tweens.push(
            new TWEEN.Tween(o, timeline)
              .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
              .onStart(function() {
                Object.assign(target, { angle: angleEnd });
              })
          )
          tweens.push(
            new TWEEN.Tween(target, timeline)
              .to({ angle: angleStart }, rotationDuration)
              .easing(TWEEN.Easing[easing.ease][easing.type])
          )
        }
      }
      return tweens;
    }
  },
  {
    name: typeNames.scale,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const scaleStart = configs.scaleStart == currentAnimationConfigValue ? configs.scaleStart : Math.ceil(configs.scaleStart) / 100;
      const scaleEnd = configs.scaleEnd == currentAnimationConfigValue ? configs.scaleEnd : Math.ceil(configs.scaleEnd) / 100;
      const scaleXStart = scaleStart == currentAnimationConfigValue ? updatedTarget.scaleX : scaleStart;
      const scaleYStart = scaleStart == currentAnimationConfigValue ? updatedTarget.scaleY : scaleStart;
      const scaleXEnd = scaleEnd == currentAnimationConfigValue ? updatedTarget.scaleX : scaleEnd;
      const scaleYEnd = scaleEnd == currentAnimationConfigValue ? updatedTarget.scaleY : scaleEnd;
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            Object.assign(target,
              {
                scaleX: scaleXStart,
                scaleY: scaleYStart,
                opacity: updatedTarget.opacity
              })})
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ scaleX: scaleXEnd, scaleY: scaleYEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  }
];

export const middleTransitions = {
  Blink: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
    const blinkCount = Math.ceil(configs.count);
    const opacityValues = Array(blinkCount)
      .fill([0.2, updatedTarget.opacity])
      .flat();
    const tweens = [
      new TWEEN.Tween(target, timeline).to({
        opacity: opacityValues
      }, duration)
        .onStart(function() {
          Object.assign(target, { opacity: updatedTarget.opacity });
        })
        .delay(startDelay)
    ];
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  Move: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
    const moveDuration = configs.intensity;
    const moveDistance = configs.distance;
    const leftValues = [];
    const remainder = duration % moveDuration;
    const count = Math.ceil(duration / moveDuration);
    const length = getRelativeLength(moveDistance, standardCanvasWidth, canvas.width);
    const moveLeft = (updatedTarget.left - length).toFixed(10) * 1;
    const moveRight = (updatedTarget.left + length).toFixed(10) * 1;
    let left = moveLeft;
    let lastTween;
    for (let i = 0; i < count; i++) {
      left = (left == moveLeft) ? moveRight : moveLeft;
      if (i == (count - 1)) {
        left = updatedTarget.left;
        if (remainder != 0) {
          lastTween = new TWEEN.Tween(target, timeline).to(
            {
              left: [left]
            }, remainder);
          break;
        }
      }
      leftValues.push(left);
    }
    const tweens = [
      new TWEEN.Tween(target, timeline).to({
        left: leftValues
      }, duration - remainder)
        .delay(startDelay)
        .onStart(function() {
          Object.assign(target, { opacity: updatedTarget.opacity });
        })
    ];
    if (lastTween)
      tweens.push(lastTween);
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  Spin: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
    const tweens = [
      new TWEEN.Tween(target, timeline).to({}, 0)
        .delay(startDelay)
        .onStart(function() {
          Object.assign(target, { opacity: updatedTarget.opacity });
        })
    ];
    const spinCount = Math.ceil(configs.count);
    const angle = updatedTarget.angle;
    const spinValues = [];
    for (let i = 0; i < spinCount; i++) {
      spinValues.push(angle + (i + 1) * animateConstants.spinDeg);
    }
    tweens.push(new TWEEN.Tween(target, timeline).to({
      angle: spinValues
    }, duration));
    tweens.push(new TWEEN.Tween(target, timeline).to({ angle }, 0));
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  HeartBeat: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
    const backUp = { scaleX: updatedTarget.scaleX, scaleY: updatedTarget.scaleY };
    const tweens = [
      new TWEEN.Tween(target, timeline).to({}, 0)
        .delay(startDelay)
        .onStart(function() {
          Object.assign(target, { opacity: updatedTarget.opacity });
        })
    ];
    const heartBeatCount = Math.ceil(configs.count);
    const heartBeatDuration = duration / heartBeatCount;
    const scaleX = updatedTarget.scaleX / 100 * 85;
    const scaleY = updatedTarget.scaleY / 100 * 85;
    for (let i = 0; i < heartBeatCount; i++) {
      tweens.push(new TWEEN.Tween(target, timeline).to({
        scaleX: [scaleX, updatedTarget.scaleX, scaleX, updatedTarget.scaleX],
        scaleY: [scaleY, backUp.scaleY, scaleY, backUp.scaleY]
      }, heartBeatDuration / 2)
        .delay(i == 0 ? i : heartBeatDuration / 2));
    }
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  Pulsate: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
    const fadeStart = configs.fadeStart == currentAnimationConfigValue ? updatedTarget.opacity : (Math.ceil(configs.fadeStart) / 100) ;
    const fadeEnd = configs.fadeEnd == currentAnimationConfigValue ? updatedTarget.opacity : (Math.ceil(configs.fadeEnd) / 100);
    const scaleStart = configs.scaleStart == currentAnimationConfigValue ? configs.scaleStart : Math.ceil(configs.scaleStart) / 100;
    const scaleEnd = configs.scaleEnd == currentAnimationConfigValue ? configs.scaleEnd : Math.ceil(configs.scaleEnd) / 100;
    const scaleXStart = scaleStart == currentAnimationConfigValue ? updatedTarget.scaleX : scaleStart;
    const scaleYStart = scaleStart == currentAnimationConfigValue ? updatedTarget.scaleY : scaleStart;
    const scaleXEnd = scaleEnd == currentAnimationConfigValue ? updatedTarget.scaleX : scaleEnd;
    const scaleYEnd = scaleEnd == currentAnimationConfigValue ? updatedTarget.scaleY : scaleEnd;
    const pulsateCount = configs.count ?? animateConstants.defaultMiddleAnimationCount;
    const tweens = [
      new TWEEN.Tween(target, timeline).to({
        opacity: fadeStart,
        scaleX: scaleXStart,
        scaleY: scaleYStart
      }, 0)
        .delay(startDelay)
        .onStart(function() {
          Object.assign(target, { opacity: updatedTarget.opacity });
        })
    ];
    const scaleXValues = [];
    const scaleYValues = [];
    const opacityValues = [];
    for (let i = 0; i < pulsateCount; i++) {
      opacityValues.push(fadeEnd);
      scaleXValues.push(scaleXEnd);
      scaleYValues.push(scaleYEnd);
      opacityValues.push(fadeStart);
      scaleXValues.push(scaleXStart);
      scaleYValues.push(scaleYStart);
    }
    tweens.push(new TWEEN.Tween(target, timeline).to({ opacity: opacityValues, scaleX: scaleXValues, scaleY: scaleYValues }, duration));
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  }
};
