/**
 * Calculates the length of tweening - based on the object's origin and the length. Similar to {@link getRelativeLength}.
 * Used in animations where the object's origin plays a part in the effect. Ex: Scale-Up uses origin "bottom", while "Zoom-In" uses origin "center"
 * @param {*} origin
 * @param {*} length
 * @returns
 */
export function getLengthByOrigin(origin, length) {
  if (origin == 'center')
    return length / 2;
  else if (origin == 'bottom')
    return length;
  return 0;
}

/**
   * Gets the position and origin properties of the {fabricObject}
   * @param {*} fabricObject
   * @param {*} direction
   * @returns {Object {top: {Number}, left: {Number}, originX: {String}, originY: {String}}}
   */
export function getchangedOriginProperties(fabricObject, direction, hasOriginChanged) {
  if (hasOriginChanged) {
    return {
      left: fabricObject.left,
      top: fabricObject.top,
      originX: fabricObject.originX,
      originY: fabricObject.originY
    };
  } else {
    let center = fabricObject.centerPoint;
    if (direction == 'bottom') {
      let top = fabricObject.top + fabricObject.scaledHeight - getLengthByOrigin(fabricObject.originY, fabricObject.scaledHeight);
      return { left: center.x, top: top, originY: 'bottom', originX: 'center' };
    }
    else
      return { left: center.x, top: center.y, originY: 'center', originX: 'center' };
  }
}

/**
   * Calculates the length of tweening - based on {standardCanvasLength} and {canvasLength}.
   * For example: If we want a tween to move 20 to the right - then for a 250px width canvas, the length to move is 20.
   * But for a 1000px canvas, the length to move is (20*1000)/250 = 80
   * @param {Number} length
   * @param {Number} standardCanvasLength
   * @param {Number} canvasLength
   * @returns
   */

export function getRelativeLength(length, standardCanvasLength, canvasLength) {
  return (length * canvasLength) / standardCanvasLength;
}