// FAQ components
const Faq = () => import(/* webpackChunkName: "dashboard" */ 'Features/faq/Main');

const faqRoute =  {
  name: 'faq',
  path: 'faq',
  component: Faq,
  meta: {
    requiresAuth: true,
    title: 'message.faq',
    breadcrumb: 'message.faq'
  }
}

const portalFaqRoute =  {
  name: 'portal-faq',
  path: '/faq',
  component: Faq,
  meta: {
    requiresAuth: true,
    title: 'message.faq',
    breadcrumb: 'message.faq'
  }
}

export const FaqRoute = faqRoute;
export const PortalFaqRoute = portalFaqRoute;