
import moment from 'moment';

const state = {
  selectedDateRangePreset: {
    dateRange: {
      startDate: moment().startOf('month'),
      endDate: moment()
    }
  }
}
// getters
const getters = {
  selectedDateRangePreset: state => {
    return state.selectedDateRangePreset;
  }
}

// mutations
const mutations = {
  setDateRange(state, dateRange) {
    state.selectedDateRangePreset = {
      dateRange: {
        startDate: dateRange[0],
        endDate: dateRange[1]
      }
    };
  }
}

const actions = {
  setDateRange(context, payload) {
    context.commit('setDateRange', payload)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
