import { AppService } from 'AppServices/appService';

const { getAccountsByIds } = new AppService();

export function getBpseIdsForAffiliateUser(businessProfileBpses, businessProfileId) {
  var businessProfileBpse = businessProfileBpses.find(b => b.coreBusinessProfileId == businessProfileId);
  if (businessProfileBpse) {
    var bpseIds = businessProfileBpse.businessProfileSearchEngineIds;
    return bpseIds;
  }
}

export async function getAffiliateAccounts(businessProfileId, bpseIds) {
  var businessProfileSearchEngines = await getAccountsByIds(businessProfileId, bpseIds);
  return businessProfileSearchEngines;
}