/**
 * Auth Module
 */

import { AppService } from 'AppServices/appService';
import { AuthService } from 'AppServices/authService';
import Bugsnag from '@bugsnag/js';
import { BusinessProfilesHelper } from 'Helpers/businessProfilesHelper';
import { Flavor } from 'Constants/storeTypes'
import { ForbiddenPortalRequested } from 'GlobalErrors/ForbiddenPortalRequested';
import { InvalidLoginCredentials } from 'GlobalErrors/InvalidLoginCredentials';
import { Languages } from '../../../constants/Languages'
import { LoginError } from 'GlobalErrors/loginError';
import Nprogress from 'nprogress';
import { UserAccountExpiredException } from 'GlobalErrors/UserAccountExpiredException';
import Vue from 'vue';
import { apiPayload } from '../../../features/creativeBuilder/externalTools/helpers/runtimeHelper';
import { i18n } from '../../../lang';
import { isJson } from 'Helpers/helpers'
import router from '../../../router';

export const getPortalDomain = () => {
  if (typeof module.hot !== 'undefined') {
    return 'roimatic.adbox.pro'
  }
  var queryParameters = new URLSearchParams(window.location.search);
  var portalDomain = queryParameters.get('portalDomain');
  // This is required for loading creative builder in DeltaX web app in embed mode.
  // In this case the portal will be the web app's portal even though the iframe is loading the roimatic portal.
  // Therefore, the iframe's url will also include the portalDomain as a query parameter
  // We'll be using this instead of the roimatic domain to get the businessProfile etc..
  if (portalDomain) {
    return portalDomain;
  }
  return window.location.host;
}

const state = {
  user: isJson(localStorage.getItem('user'))
    ? JSON.parse(localStorage.getItem('user'))
    : null,
  //eslint-disable-next-line
  portalDomain: getPortalDomain(),
  // this state flag is used to indicate that a BP switch has been triggered
  isBpSwitcherOn: false,
  businessProfileId: null,
  selectedLanguageName: Languages[0].name,
  portal: null,
  currencyInfo: null,
  timezoneId: null,
  businessProfileBpses: null,
  businessProfileSearchEngineId: null
};

// getters
const getters = {
  user: (state) => {
    return state.user;
  },
  portalDomain: (state) => {
    return state.portalDomain;
  },
  isBpSwitcherOn: state => state.isBpSwitcherOn,
  businessProfileId: state => state.businessProfileId,
  selectedLanguageName: state => state.selectedLanguageName,
  portal: state => state.portal,
  currencyInfo: state => state.currencyInfo,
  timezoneId: state => state.timezoneId,
  businessProfileBpses: (state) => {
    return isJson(state.user.businessProfileBpses)
      ? JSON.parse(state.user.businessProfileBpses)
      : null
  },
  businessProfileSearchEngineId: state => state.businessProfileSearchEngineId
};

// actions
const actions = {
  async signUpUser(context, payload) {
    const { user } = payload;
    context.commit('signUpUser');
    const { signUpUser } = new AuthService();
    try {
      var response = await signUpUser(user);
      user.id = response.id;
      await login(user);
      Nprogress.done();
      setTimeout(() => {
        context.commit('signUpUserSuccess', {
          user
        });
      }, 500);
    } catch (error) {
      context.commit('signUpUserFailure');
    }
  },
  setUserData(context, payload) {
    context.commit('setUserData', payload);
  },
  async loginUser(context, payload) {
    const { loginDetails, skipFailureNotification } = payload;
    context.commit('loginUser');
    const { login } = new AuthService();
    const { getUserData } = new AppService();
    try {
      let loginResponse = await login(loginDetails, loginDetails.portal);
      Nprogress.done();
      var user = await getUserData(loginDetails.emailAddress);
      await context.commit('loginUserSuccess', {
        user,
        roleIds: loginResponse.roleIds,
        businessProfileBpses: loginResponse.businessProfileSearchEngineIds
      });
      await context.commit('setUserSelectedLanguage');
    } catch (error) {
      let errorDetails = {
        error: error,
        userEmail: loginDetails.emailAddress,
        skipFailureNotification
      }
      context.commit('loginUserFailure', errorDetails);
      throw error;
    }
  },
  async loginWithEmbedToken({ commit }, embedToken) {
    const { loginWithEmbed } = new AuthService();
    const { getUserData } = new AppService();
    const { getBusinessProfiles } = new BusinessProfilesHelper();
    try {
      let loginDetails = await loginWithEmbed(embedToken);
      Nprogress.done();
      localStorage.setItem('embedLoginDetails', JSON.stringify(loginDetails));
      if (loginDetails.isUserSignedUp) {
        localStorage.setItem('accessToken', loginDetails.accessToken);
        var user = await getUserData(loginDetails.emailAddress);
        commit('loginUserSuccess', { user });
        await commit('setUserSelectedLanguage');
        let isRoimatic = loginDetails.flavor != Flavor.deltaX;
        // fetch businessProfiles based on the flavor
        let businessProfiles = await getBusinessProfiles(isRoimatic);
        if (businessProfiles && businessProfiles.length > 0) {
          user.businessProfiles = businessProfiles;
          commit('setUser', user);
          commit('setBusinessProfileId', loginDetails.businessProfileId || businessProfiles[0].id);
        }
      }
    } catch (error) {
      let errorDetails = {
        error: error,
        userEmail: loginDetails.emailAddress
      }
      commit('loginUserFailure', errorDetails);
      throw error;
    }
  },
  logoutUser(context) {
    Nprogress.start();
    context.commit('logoutUser');
  },
  setUser(context, payload) {
    if (payload) {
      Bugsnag.setUser(payload.id, payload.emailAddress, `${payload.firstName} ${payload.lastName}`);
    }
    context.commit('setUser', payload);
  },
  setBusinessProfileId(context, payload) {
    apiPayload.businessProfileId = payload;
    context.commit('setBusinessProfileId', payload);
  },
  enableBpSwitcherMode(context) {
    context.commit('setBpSwitcherMode', true);
  },
  disableBpSwitcherMode(context) {
    context.commit('setBpSwitcherMode', false);
  },
  setSelectedLanguageName(context, payload) {
    context.commit('setSelectedLanguageName', payload);
  },
  setUserSelectedLanguage(context) {
    context.commit('setUserSelectedLanguage');
  },
  setPortalDomain(context, payload) {
    context.commit('setPortalDomain', payload);
  },
  async setPortal(context) {
    const { getPortalData } = new AuthService();
    try {
      let portalData;
      if (context.state.portalDomain === 'mobile.adbox.pro') {
        portalData = {};
      } else {
        portalData = await getPortalData(context.state.portalDomain);
      }
      if (portalData) {
        let featureFlags = portalData.featureFlags && JSON.parse(portalData.featureFlags);
        portalData.isSignupEnabled = true;
        portalData.isBizgain = false;
        portalData.isBrandPortal = false;
        portalData.isCampaignLaunchDisabled = false;
        portalData.isScheduleEditEnabled = true;
        portalData.isAffiliate = false;
        portalData.isGoogleLoginEnabled = false;
        if (featureFlags) {
          if ('IsSignupEnabled' in featureFlags) {
            portalData.isSignupEnabled = featureFlags.IsSignupEnabled;
          }
          if ('IsBizgain' in featureFlags) {
            portalData.isBizgain = featureFlags.IsBizgain;
          }
          if ('IsBrandPortal' in featureFlags) {
            portalData.isBrandPortal = featureFlags.IsBrandPortal;
          }
          if ('IsCreativeBuilderPortal' in featureFlags) {
            portalData.isCreativeBuilderPortal = featureFlags.IsCreativeBuilderPortal;
          }
          if ('IsRichMediaAdBuilderPortal' in featureFlags) {
            portalData.isRichMediaAdBuilderPortal = featureFlags.IsRichMediaAdBuilderPortal;
          }
          if ('IsCampaignLaunchDisabled' in featureFlags) {
            portalData.isCampaignLaunchDisabled = featureFlags.IsCampaignLaunchDisabled;
          }
          if ('IsScheduleEditEnabled' in featureFlags) {
            portalData.isScheduleEditEnabled = featureFlags.IsScheduleEditEnabled;
          }
          if ('IsHubLifetimeBudgetCapEnabled' in featureFlags) {
            portalData.isHubLifetimeBudgetCapEnabled = featureFlags.IsHubLifetimeBudgetCapEnabled;
          }
          if ('IsAffiliate' in featureFlags) {
            portalData.isAffiliate = featureFlags.IsAffiliate;
          }
          if ('IsGoogleLoginEnabled' in featureFlags) {
            portalData.isGoogleLoginEnabled = featureFlags.IsGoogleLoginEnabled;
          }
        }
        localStorage.setItem('portal', JSON.stringify(portalData));
        context.commit('setPortal', portalData);
      }
    } catch (error) {
      // if portal doesn't exist or the API call fails for some reason, then portal won't be set in state.
      // This is handled in router guard where its redirected to not found page
    }
  },
  setCurrencyInfo(context, payload) {
    context.commit('setCurrencyInfo', payload);
  },
  setTimezoneId(context, payload) {
    context.commit('setTimezoneId', payload);
  },
  setBusinessProfileBpses(context, payload) {
    context.commit('setBusinessProfileBpses', payload);
  },
  setBusinessProfileSearchEngineId(context, payload) {
    context.commit('setBusinessProfileSearchEngineId', payload);
  },
  loginUserFailure(context, payload) {
    context.commit('loginUserFailure', payload);
  },
  async loginUserWithProvider(context, payload) {
    const { loginWithProviderDetails, skipFailureNotification } = payload;
    context.commit('loginUser');
    const { loginWithProvider } = new AuthService();
    const { getUserData } = new AppService();
    try {
      let loginResponse = await loginWithProvider(loginWithProviderDetails);
      Nprogress.done();
      var user = await getUserData(loginWithProviderDetails.emailAddress);
      await context.commit('loginUserSuccess', {
        user,
        roleIds: loginResponse.roleIds,
        businessProfileBpses: loginResponse.businessProfileSearchEngineIds
      });
      await context.commit('setUserSelectedLanguage');
    } catch (error) {
      let errorDetails = {
        error: error,
        userEmail: loginWithProviderDetails.emailAddress,
        skipFailureNotification
      }
      context.commit('loginUserFailure', errorDetails);
      throw error;
    }
  }
};

// mutations
const mutations = {
  loginUser() {
    Nprogress.start();
  },
  setUserData(state, payload) {
    state.user = payload;
  },
  async setUserSelectedLanguage(state) {
    let user = state.user;
    let selectedLanguage;
    const { getAllLanguages } = new AppService();
    if (user && user.languageId) {
      let languages = await getAllLanguages();
      var languageCode = languages.find(l => l.id == user.languageId).format;
      languageCode = (languageCode == 'pt-PT' ? 'pt_PT' : (languageCode == 'en' ? 'en-us' : languageCode));
      selectedLanguage = Languages.find(l => l.code == languageCode);
    } else if (state.portal && state.portal.portalDefaultConfig) {
      let portalDefaultConfig = JSON.parse(state.portal.portalDefaultConfig);
      if (portalDefaultConfig && portalDefaultConfig.locale) {
        let portalLocale = portalDefaultConfig.locale;
        selectedLanguage = Languages.find(l => l.code == portalLocale);
      }
    }
    if (selectedLanguage && selectedLanguage.code) {
      i18n.locale = selectedLanguage.code
      state.selectedLanguageName = selectedLanguage.name
    } else {
      i18n.locale = Languages[0].code;
      state.selectedLanguageName = Languages[0].name
    }
  },
  async loginUserSuccess(state, loginData) {
    let { user, roleIds, businessProfileBpses } = loginData;
    user.isSignedIn = true;
    user.roleIds = roleIds;
    user.businessProfileBpses = JSON.stringify(businessProfileBpses);
    localStorage.setItem('user', JSON.stringify(user));
    state.user = user;
    state.businessProfileBpses = businessProfileBpses;
    setTimeout(function () {
      Vue.notify({
        group: 'loggedIn',
        type: 'success',
        text: `Hey, ${user.firstName}!`
      });
    }, 1500);
  },
  loginUserFailure(state, errorDetails) {
    if (errorDetails.skipFailureNotification) {
      return;
    }

    Nprogress.done();
    let error = errorDetails.error;
    if (error instanceof ForbiddenPortalRequested) {
      Vue.notify({
        group: 'transientErrors',
        type: 'error',
        text: i18n.tc('message.forbiddenPortalRequested')
      });
    } else if (error instanceof InvalidLoginCredentials) {
      Vue.notify({
        group: 'transientErrors',
        type: 'error',
        text: i18n.tc('message.invalidLoginCredentails')
      });
    } else if (error instanceof UserAccountExpiredException) {
      Vue.notify({
        group: 'transientErrors',
        type: 'error',
        text: `${i18n.tc('message.userAccountExpired')}: ${errorDetails.userEmail}`
      })
    } else {
      let loginError = new LoginError(error);
      Vue.notify({
        group: 'loggedIn',
        type: 'error',
        text: loginError.message
      });
    }
  },
  logoutUser(state) {
    state.user = null;
    state.businessProfileId = null;
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('roimaticConfiguration');
    localStorage.removeItem('uplocal_callback_phone_number');
    Nprogress.done();
    router.push('/login');
    i18n.locale = 'en';
  },
  signUpUser() {
    Nprogress.start();
  },
  signUpUserSuccess(state, loginData) {
    let { user } = loginData;
    state.user = localStorage.setItem('user', user);
    Vue.notify({
      group: 'loggedIn',
      type: 'success',
      text: 'Account Created!'
    });
  },
  signUpUserFailure(state, error) {
    Nprogress.done();
    Vue.notify({
      group: 'loggedIn',
      type: 'error',
      text: error.message
    });
  },
  setUser(state, payload) {
    if (payload) {
      localStorage.setItem('user', JSON.stringify(payload));
    }
    state.user = payload;
  },
  setBusinessProfileId(state, payload) {
    state.businessProfileId = payload;
  },
  setBpSwitcherMode(state, bpSwitcherMode) {
    state.isBpSwitcherOn = bpSwitcherMode;
  },
  setSelectedLanguageName(state, payload) {
    state.selectedLanguageName = payload;
  },
  setPortal(state, payload) {
    state.portal = payload;
  },
  setPortalDomain(state, payload) {
    state.portalDomain = payload;
  },
  setCurrencyInfo(state, currencyInfo) {
    state.currencyInfo = currencyInfo;
  },
  setTimezoneId(state, timezoneId) {
    state.timezoneId = timezoneId;
  },
  setBusinessProfileBpses(state, payload) {
    state.businessProfileBpses = payload;
  },
  setBusinessProfileSearchEngineId(state, payload) {
    state.businessProfileSearchEngineId = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
