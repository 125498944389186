const CreativeBuilder = () => import(/* webpackChunkName: "creativeBuilder" */ 'Features/creativeBuilder/Main');
const VariantConfig = () => import(/* webpackChunkName: "variantConfig" */ 'Features/creativeBuilder/components/Variants/VariantConfig');
const ExportForm = () => import(/* webpackChunkName: "exportForm" */ 'Features/creativeBuilder/components/Export/ExportForm/ExportForm');
const creativeBuilderRoute = {
  name: 'creativeBuilder',
  path: 'creativeBuilder',
  component: CreativeBuilder,
  meta: {
    requiresAuth: true,
    title: 'message.creativeBuilder',
    breadcrumb: 'message.creativeBuilder',
    customLayout: true
  },
  children: [
    {
      name: 'creativeBuilderVariant',
      path: 'variant',
      component: VariantConfig
    }
  ]
};

const creativeBuilderTemplateRoute = {
  name: 'creativeBuilderTemplate',
  path: 'creativeBuilder/:templateId',
  component: CreativeBuilder,
  meta: {
    requiresAuth: true,
    title: 'message.creativeBuilder',
    breadcrumb: 'message.creativeBuilder',
    customLayout: true
  },
  children: [
    {
      name: 'creativeBuilderTemplateVariant',
      path: 'variant',
      component: VariantConfig
    },
    {
      name: 'creativeBuilderExport',
      path: 'export',
      beforeEnter: (to, from, next) => {
        const { businessProfileId, templateId } = to.params;
        window.location.href = process.env.VUE_APP_VARIANT_BUILDER_URL + `app/${businessProfileId}/exports/?templates=${templateId}`;
      }
    },
    {
      name: 'creativeBuilderAddExport',
      path: 'export/add',
      component: ExportForm
    },
    {
      name: 'creativeBuilderExportPreset',
      path: 'export/preset',
      component: ExportForm,
      props: {
        isSetupPreset: true
      }
    },
    {
      name: 'creativeBuilderViewExport',
      path: 'export/:exportId',
      component: ExportForm,
      props: {
        isFormReadOnly: true
      }
    },
    {
      name: 'creativeBuilderCloneExport',
      path: 'export/:exportId/clone',
      component: ExportForm,
      props: {
        isClone: true
      }
    },
    {
      name: 'creativeBuilderEditExport',
      path: 'export/:exportId/edit',
      component: ExportForm
    }
  ]
};

const creativeBuilderExportPreview = {
  name: 'creativeBuilderExportPreview',
  path: 'creativeBuilder/:templateId/export/:exportId/preview',
  component: CreativeBuilder,
  meta: {
    requiresAuth: true,
    title: 'message.creativeBuilder',
    breadcrumb: 'message.creativeBuilder',
    customLayout: true
  }
}

export default [
  creativeBuilderRoute,
  creativeBuilderTemplateRoute,
  creativeBuilderExportPreview
];
