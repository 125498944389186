const Assets = () => import(/* webpackChunkName: "assets" */ 'Features/assets/Main');

export default {
  name: 'assets',
  path: 'assets',
  component: Assets,
  meta: {
    requiresAuth: true,
    title: 'message.assets',
    breadcrumb: 'message.assets'
  }
}
