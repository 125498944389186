export const DurationTypes = Object.freeze({
  LIFETIME: 2,
  DAILY: 1
})
export const CollectionTypes = Object.freeze({
  CUSTOM: 'custom',
  SMART: 'smart'
})

export const CampaignSplitTypes = Object.freeze({
  OneCampaignPerStore: 1,
  OneCampaignForAllStores: 2
})

export const AdvancedSettingsEntityTypes = Object.freeze({
  CHANNELS: { id: 1, name: 'Channels', attribute: 'channels' },
  COLLECTIONS: { id: 2, name: 'Collections', attribute: 'collections' },
  PRODUCTS: { id: 3, name: 'Products', attribute: 'products' },
  SERVICE_AREAS: { id: 4, name: 'Service Areas', attribute: 'serviceAreas' },
  PLACEMENTS: { id: 5, name: 'Placement', attribute: 'placement' }
})

export const AdvancedSettingsFilterTypes = Object.freeze({
  INCLUDE: { id: 1, name: 'includes' },
  EXCLUDE: { id: 2, name: 'excludes' },
  STARTS_WITH: { id: 3, name: 'starts with' },
  GREATER_THAN: { id: 4, name: 'greater than' },
  LESSER_THAN: { id: 5, name: 'lesser than' },
  EQUALS: { id: 6, name: 'equals' },
  DOESNOTEQUALS: { id: 7, name: 'not equals' }
})

export const FilterTypesWithCustomValueSupport = Object.freeze([
  AdvancedSettingsFilterTypes.INCLUDE.id,
  AdvancedSettingsFilterTypes.EXCLUDE.id,
  AdvancedSettingsFilterTypes.STARTS_WITH.id,
  AdvancedSettingsFilterTypes.GREATER_THAN.id,
  AdvancedSettingsFilterTypes.LESSER_THAN.id
])

// Deltax Feed Status
export const productFeedStatus = {
  none: 0,
  notGenerated: 1,
  generating: 2,
  done: 3,
  failed: 4
};

export const MultipleInputFilterTypes = Object.freeze([AdvancedSettingsFilterTypes.INCLUDE.id, AdvancedSettingsFilterTypes.EXCLUDE.id, AdvancedSettingsFilterTypes.STARTS_WITH.id, AdvancedSettingsFilterTypes.EQUALS.id, AdvancedSettingsFilterTypes.DOESNOTEQUALS.id]);

export const AdvancedSettingsSupportedFilters = Object.freeze({
  // Id: [AdvancedSettingsFilterTypes.INCLUDE, AdvancedSettingsFilterTypes.EXCLUDE],
  itemGroupId: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS, AdvancedSettingsFilterTypes.STARTS_WITH],
  title: [AdvancedSettingsFilterTypes.INCLUDE, AdvancedSettingsFilterTypes.EXCLUDE, AdvancedSettingsFilterTypes.STARTS_WITH],
  availability: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS],
  condition: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS],
  brand: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS, AdvancedSettingsFilterTypes.STARTS_WITH],
  ageGroup: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS],
  gender: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS],
  material: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS, AdvancedSettingsFilterTypes.STARTS_WITH],
  pattern: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS, AdvancedSettingsFilterTypes.STARTS_WITH],
  productType: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS, AdvancedSettingsFilterTypes.STARTS_WITH],
  salePrice: [AdvancedSettingsFilterTypes.GREATER_THAN, AdvancedSettingsFilterTypes.LESSER_THAN],
  price: [AdvancedSettingsFilterTypes.GREATER_THAN, AdvancedSettingsFilterTypes.LESSER_THAN],
  color: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS],
  size: [AdvancedSettingsFilterTypes.EQUALS, AdvancedSettingsFilterTypes.DOESNOTEQUALS]
})

export const CreativeTemplateTypes = Object.freeze({
  SOCIAL: 1,
  SEARCH: 2,
  BANNER: 3,
  LINKEDIN: 4
})
//added for campaignForm LocalBussiness Objective
export const Objectives = {
  Traffic: 1,
  Leads: 2
}
export const BUDGET_DATES = 'budgetAndDates';
export const AUDIENCE = 'audience';
export const CREATIVES = 'creatives';
export const PAYMENT_REVIEW = 'paymentAndReview';

export const WeekDays = [{
  id: 0,
  name: 'Sunday',
  shortName: 'Sun'
},
{
  id: 1,
  name: 'Monday',
  shortName: 'Mon'
},
{
  id: 2,
  name: 'Tuesday',
  shortName: 'Tue'
},
{
  id: 3,
  name: 'Wednesday',
  shortName: 'Wed'
},
{
  id: 4,
  name: 'Thursday',
  shortName: 'Thu'
},
{
  id: 5,
  name: 'Friday',
  shortName: 'Fri'
}, {
  id: 6,
  name: 'Saturday',
  shortName: 'Sat'
}]

export const USD = 'USD';
export const marketingStrategies = [
  {
    id: 1,
    name: 'message.campaignLaunch.reTargetingPlusProspecting'
  }
  // {
  //     id: 2,
  //     name: "Prefer Re-targeting",
  // },
  // {
  //     id: 3,
  //     name: "Prefer Prospecting"
  // }
];

export const scheduleTimezoneTypes = [
  {
    value: 'USER',
    name: 'message.campaignLaunch.usersTimeZone'
  },
  {
    value: 'ADVERTISER',
    name: 'message.campaignLaunch.adAccountsTimezone'
  }
];

export const displayTime = [
  {
    value: 0,
    label: '12 AM (Beginning of the day)'
  },

  {
    value: 60,
    label: '1 AM'
  },

  {
    value: 120,
    label: '2 AM'
  },

  {
    value: 180,
    label: '3 AM'
  },

  {
    value: 240,
    label: '4 AM'
  },

  {
    value: 300,
    label: '5 AM'
  },

  {
    value: 360,
    label: '6 AM'
  },

  {
    value: 420,
    label: '7 AM'
  },

  {
    value: 480,
    label: '8 AM'
  },

  {
    value: 540,
    label: '9 AM'
  },

  {
    value: 600,
    label: '10 AM'
  },

  {
    value: 660,
    label: '11 AM'
  },

  {
    value: 720,
    label: '12 PM'
  },

  {
    value: 780,
    label: '1 PM'
  },

  {
    value: 840,
    label: '2 PM'
  },

  {
    value: 900,
    label: '3 PM'
  },

  {
    value: 960,
    label: '4 PM'
  },

  {
    value: 1020,
    label: '5 PM'
  },

  {
    value: 1080,
    label: '6 PM'
  },

  {
    value: 1140,
    label: '7 PM'
  },

  {
    value: 1200,
    label: '8 PM'
  },

  {
    value: 1260,
    label: '9 PM'
  },

  {
    value: 1320,
    label: '10 PM'
  },

  {
    value: 1380,
    label: '11 PM'
  },

  {
    value: 1440,
    label: '12 AM (End of the day)'
  }
]

export const SchedulingModes = {
  RunContinuously: 1,
  Schedule: 2
}

export const Engines = Object.freeze({
  Google: 1,
  Facebook: 5,
  Twitter: 6
});

export const PlacementTypes = {
  Mobile: 1,
  HomePage: 2,
  SearchHeader: 3,
  SearchResults: 4,
  RightColumn: 5,
  Other: 6
};

export const channels = [
  { id: Engines.Facebook, name: 'Facebook' },
  // { id: Engines.Twitter, name: "Twitter" },
  { id: Engines.Google, name: 'Google' }
]

export const placements = [
  { id: PlacementTypes.Mobile, name: 'Mobile' },
  { id: PlacementTypes.HomePage, name: 'Home Page' },
  { id: PlacementTypes.SearchHeader, name: 'Search Header' },
  { id: PlacementTypes.SearchResults, name: 'Search Results' },
  { id: PlacementTypes.RightColumn, name: 'Right Column' },
  { id: PlacementTypes.Other, name: 'Other' }
];

export const dayPartingScheduleTypes = Object.freeze({
  AlwaysOn: 1,
  Custom: 3
})

export const DeviceTypes = Object.freeze({
  Desktop: 1,
  Mobile: 2
});

export const defaultProductGroups = Object.freeze({
  Gender: 'gender',
  AgeGroup: 'ageGroup',
  ProductType: 'productType'
})

export const placementOption = Object.freeze({
  Manual: 'Manual',
  Automatic: 'Automatic'
})

export const facebookAdPlacementValues = {
  AUDIENCE_NETWORK_INSTREAM_VIDEO: 1,
  AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE: 2,
  AUDIENCE_NETWORK_OUTSTREAM_VIDEO: 3,
  AUDIENCE_NETWORK_REWARDED_VIDEO: 4,
  DESKTOP_FEED_STANDARD: 5,
  FACEBOOK_STORY_MOBILE: 6,
  INSTAGRAM_EXPLORE_CONTEXTUAL: 7,
  INSTAGRAM_EXPLORE_IMMERSIVE: 8,
  INSTAGRAM_STANDARD: 9,
  INSTAGRAM_STORY: 10,
  INSTANT_ARTICLE_RECIRCULATION_AD: 11,
  INSTANT_ARTICLE_STANDARD: 12,
  INSTREAM_VIDEO_DESKTOP: 13,
  INSTREAM_VIDEO_IMAGE: 14,
  INSTREAM_VIDEO_MOBILE: 15,
  JOB_BROWSER_DESKTOP: 16,
  JOB_BROWSER_MOBILE: 17,
  MARKETPLACE_MOBILE: 18,
  MESSENGER_MOBILE_INBOX_MEDIA: 19,
  MESSENGER_MOBILE_STORY_MEDIA: 20,
  MOBILE_BANNER: 21,
  MOBILE_FEED_BASIC: 22,
  MOBILE_FEED_STANDARD: 23,
  MOBILE_FULLWIDTH: 24,
  MOBILE_INTERSTITIAL: 25,
  MOBILE_MEDIUM_RECTANGLE: 26,
  MOBILE_NATIVE: 27,
  RIGHT_COLUMN_STANDARD: 28,
  SUGGESTED_VIDEO_DESKTOP: 29,
  SUGGESTED_VIDEO_MOBILE: 30,
  WATCH_FEED_HOME: 31,
  WATCH_FEED_MOBILE: 32,
  FACEBOOK_REELS_MOBILE: 33,
  INSTAGRAM_REELS: 34
}

export const placementPreviews = [
  {
    value: 'RIGHT_COLUMN_STANDARD',
    label: 'message.campaignLaunch.desktopRightColumn',
    height: 300,
    width: 400,
    platform: 'Facebook',
    platformPosition: 'Right hand column'
  },
  {
    value: 'DESKTOP_FEED_STANDARD',
    label: 'message.campaignLaunch.desktopNewsFeed',
    height: 600,
    width: 520,
    platform: 'Facebook',
    platformPosition: 'Feed'
  },
  {
    value: 'MOBILE_FEED_STANDARD',
    label: 'message.campaignLaunch.mobileNewsFeed',
    height: 400,
    width: 400,
    platform: 'Facebook',
    platformPosition: 'Feed'
  },
  {
    value: 'MOBILE_FEED_BASIC',
    label: 'message.campaignLaunch.basicMobileFeed',
    height: 300,
    width: 400,
    platform: 'Facebook',
    platformPosition: 'Feed'
  },
  {
    value: 'INSTANT_ARTICLE_STANDARD',
    label: 'message.campaignLaunch.instantArticles',
    height: 500,
    width: 400,
    platform: 'Facebook',
    platformPosition: 'Instant article'
  },
  {
    value: 'MARKETPLACE_MOBILE',
    label: 'message.campaignLaunch.mobileMarketplace',
    height: 300,
    width: 400,
    platform: 'Facebook',
    platformPosition: 'Marketplace'
  },
  {
    value: 'FACEBOOK_STORY_MOBILE',
    label: 'message.campaignLaunch.facebookStory',
    height: 580,
    width: 400,
    platform: 'Facebook',
    platformPosition: 'Story'
  },
  {
    value: 'INSTAGRAM_STANDARD',
    label: 'message.campaignLaunch.instagramFeed',
    height: 500,
    width: 400,
    platform: 'Instagram',
    platformPosition: 'Stream'
  },
  {
    value: 'INSTAGRAM_STORY',
    label: 'message.campaignLaunch.instagramStory',
    height: 580,
    width: 400,
    platform: 'Instagram',
    platformPosition: 'Story'
  },
  {
    value: 'MOBILE_INTERSTITIAL',
    label: 'message.campaignLaunch.audienceNetworkInterstitial',
    height: 580,
    width: 400,
    platform: 'Audience network',
    platformPosition: 'Classic'
  },
  {
    value: 'MOBILE_BANNER',
    label: 'message.campaignLaunch.audienceNetworkBanner',
    height: 300,
    width: 400,
    platform: 'Audience network',
    platformPosition: 'Classic'
  },
  {
    value: 'MOBILE_MEDIUM_RECTANGLE',
    label: 'message.campaignLaunch.audienceNetworkMediumRectangle',
    height: 500,
    width: 400,
    platform: 'Audience network',
    platformPosition: 'Classic'
  },
  {
    value: 'MOBILE_NATIVE',
    label: 'message.campaignLaunch.audienceNetworkNative',
    height: 500,
    width: 400,
    platform: 'Audience network',
    platformPosition: 'Classic'
  },
  {
    value: 'AUDIENCE_NETWORK_INSTREAM_VIDEO',
    label: 'message.campaignLaunch.audienceNetworkInstreamVideo',
    height: 500,
    width: 500,
    platform: 'Audience network',
    platformPosition: 'Instream video'
  },
  {
    value: 'AUDIENCE_NETWORK_REWARDED_VIDEO',
    label: 'message.campaignLaunch.audienceNetworkRewardedVideo',
    height: 500,
    width: 500,
    platform: 'Audience network',
    platformPosition: 'Rewarded video'
  },
  {
    value: 'MESSENGER_MOBILE_STORY_MEDIA',
    label: 'message.campaignLaunch.messengerStories',
    height: 600,
    width: 400,
    platform: 'Messenger',
    platformPosition: 'Story'
  },
  {
    value: 'MESSENGER_MOBILE_INBOX_MEDIA',
    label: 'message.campaignLaunch.messengerInbox',
    height: 400,
    width: 400,
    platform: 'Messenger',
    platformPosition: 'Sponsored Messages'
  },
  {
    value: 'FACEBOOK_REELS_MOBILE',
    label: 'message.campaignLaunch.fbReels',
    height: 600,
    width: 400,
    platform: 'Facebook',
    platformPosition: 'Reels'
  },
  {
    value: 'INSTAGRAM_REELS',
    label: 'message.campaignLaunch.instagramReels',
    height: 600,
    width: 400,
    platform: 'Instagram',
    platformPosition: 'Reels'
  }
];

export const coPromoteUserMappings = {
  'sephora@roimatic.com': ['Sephora Collection'],
  'tomfordbeauty@roimatic.com': ['Tom Ford Beauty'],
  'tarte@roimatic.com': ['tarte'],
  'makeupforever@roimatic.com': ['Make Up For Ever'],
  'fentybeauty@roimatic.com': ['Fenty Beauty'],
  'lauramercier@roimatic.com': ['Laura Mercier'],
  'shuuemura@roimatic.com': ['Shu Uemura'],
  'nars@roimatic.com': ['NARS'],
  'charlottetilbury@roimatic.com': ['Charlotte Tilbury'],
  'dior@roimatic.com': ['DIOR']
}

export const destinationType = {
  DestinationUrl: 0,
  Page: 1,
  App: 2,
  Event: 3,
  Messenger: 4,
  SponsoredMessage: 5,
  WhatsApp: 6,
  InstagramDirect: 7,
  AppLinksAutomatic: 8,
  Facebook: 9,
  Undefined: 10
}

export const objectives = {
  PageLikes: 3,
  EventResponses: 6,
  PostEngagement: 7,
  Conversions: 8,
  Traffic: 11,
  VideoViews: 12,
  ProductCatalogSales: 13,
  AppInstalls: 15,
  BrandAwareness: 16,
  Reach: 18,
  LeadGeneration: 14,
  Messages: 20
}

export const MediaTypes = Object.freeze({
  IMAGES: 'Images',
  VIDEOS: 'Videos'
})

export const CampaignLaunchStatus = {
  ReadyForExecution: 1,
  ExecutionInProgress: 2,
  ReadyForLaunch: 3,
  LaunchComplete: 4,
  Failed: 5
}

export const PromotionLaunchType = Object.freeze({
  Standard: 0,
  Template: 1
})

export const PromotionType = {
  Paid: 1,
  Organic: 2
}

export const PromotionLaunchTypes = Object.freeze([{
  id: PromotionLaunchType.Standard,
  text: 'message.campaignLaunch.promotion',
  icon: 'subtitles'
}, {
  id: PromotionLaunchType.Template,
  text: 'message.campaignLaunch.template',
  icon: 'style'
}])

export const PromotionVersionStatus = {
  Draft: 1,
  Enabled: 2,
  Archived: 3
}

export const PaymentDetails = [
  {
    id: 1,
    key: 'message.walletMessages.bankAccountNumber',
    value: null,
    showTooltip: true,
    localeSpecific: true
  },
  {
    id: 2,
    key: 'message.walletMessages.ifsc',
    value: null,
    showTooltip: true,
    localeSpecific: true
  },
  {
    id: 3,
    key: 'message.walletMessages.bankName',
    value: null,
    showTooltip: true,
    localeSpecific: true
  },
  {
    id: 4,
    key: 'message.walletMessages.upiAddress',
    value: null,
    showTooltip: true,
    localeSpecific: true,
    hideOnEmpty: true
  },
  {
    id: 5,
    key: 'message.walletMessages.beneficiaryName',
    value: null,
    showTooltip: false,
    localeSpecific: true
  }
]

export const PaymentGatewayTypes = {
  Stripe: 1,
  Razorpay: 2
}

export const BillingAccountStatus = {
  ONBOARDING_INCOMPLETE: 1,
  CONFIGURED: 2
}

export const FundingSource = {
  HQ: 1,
  LOCAL: 2
}