/**
 * Sidebar Module
 */

import { settingsMenu, featuresMenu, statsMenuItems, walletMenuItem, affiliateMenuItem } from './data.js';

const state = {
  settingsMenu: new Set(settingsMenu), // using set here to ensure there are no duplicates added
  featuresMenu: new Set(featuresMenu)
}

const getters = {
  settingsMenu: (state, getters) => [...state.settingsMenu].filter(menu => getters.supportedMenus.includes(menu.key)),
  featuresMenu: (state, getters) => [...state.featuresMenu]
    .filter(menu => getters.supportedMenus
      .includes(menu.key))
}

const actions = {
  setActiveMenuGroup(context, payload) {
    context.commit('setActiveMenuGroupHandler', payload);
  },
  addStatsMenuItem(context) {
    context.commit('addStatsMenuItem');
  },
  addWalletMenuItem(context) {
    context.commit('addWalletMenuItem')
  },
  addAffiliateMenuItem(context) {
    context.commit('addAffiliateMenuItem')
  },
  resetSettingsMenu(context) {
    context.commit('resetSettingsMenu');
  },
  resetSidebarItems(context) {
    context.commit('resetSidebarItems');
  }
}

const mutations = {
  setActiveMenuGroupHandler(state, router) {
    let fullPath = router.history.current.fullPath;
    let path = fullPath.split('/');
    let matchedPath = '/' + path[2] + '/' + path[3];
    for (const category in state.menus) {
      for (const menuGroup in state.menus[category]) {
        if (state.menus[category][menuGroup].items !== null) {
          for (const matched in state.menus[category][menuGroup].items) {
            if (state.menus[category][menuGroup].items[matched].path == matchedPath || state.menus[category][menuGroup].items[matched].path == fullPath) {
              state.menus[category][menuGroup].active = true;
            }
          }
        }
      }
    }
  },
  addStatsMenuItem(state) {
    state.featuresMenu = new Set([...statsMenuItems, ...featuresMenu])
  },
  addWalletMenuItem(state) {
    state.featuresMenu.add(walletMenuItem);
  },
  addAffiliateMenuItem(state) {
    state.featuresMenu = new Set(affiliateMenuItem);
  },
  resetSettingsMenu(state) {
    state.settingsMenu = new Set(settingsMenu);
  },
  resetSidebarItems(state) {
    state.settingsMenu = new Set(settingsMenu);
    state.featuresMenu = new Set(featuresMenu);
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}