const Promotions = () =>
  import(/* webpackChunkName: "promotions" */ 'Features/promotions/Main');
const PromotionsGallery = () =>
  import(/* webpackChunkName: "PromotionsGallery" */ 'Features/promotions/components/PromotionsGallery');
const PostLaunch = () =>
  import(/* webpackChunkName: "PostLaunch" */ 'Features/promotions/components/PostLaunch');

const postLaunchRoute = {
  name: 'postLaunch',
  path: ':promotionId/postLaunch',
  component: PostLaunch,
  meta: {
    requiresAuth: true,
    title: 'message.postLaunchHeader',
    breadcrumb: 'message.postLaunchHeader',
    isBackBtnEnabled: true
  }
};

const promotionsGalleryRoute = {
  name: 'promotionsGallery',
  path: '',
  component: PromotionsGallery,
  meta: {
    requiresAuth: true,
    title: 'message.promotionsHeader',
    breadcrumb: 'message.promotionsHeader',
    isBackBtnEnabled: true
  }
};

const promotionsRoute = {
  name: 'promotions',
  path: 'promotions',
  component: Promotions,
  children: [
    promotionsGalleryRoute,
    postLaunchRoute
  ]
}
export const PostLaunchRoute = postLaunchRoute;
export const PromotionsGalleryRoute = promotionsGalleryRoute;
export const PromotionsRoute = promotionsRoute;