const ProductFeed = () => import(/* webpackChunkName: "productFeed" */ 'Features/productFeed/Main');

export default {
  name: 'productFeed',
  path: 'productFeed',
  component: ProductFeed,
  meta: {
    requiresAuth: true,
    title: 'message.productFeed',
    breadcrumb: 'message.productFeed'
  }
}
