import api from 'Api';

export class ProductFeedService {
  async getProductFilters(businessProfileId) {
    let response = await api.get(`/businessProfiles/${businessProfileId}/productFeeds/products/filters`);
    return response.data.data;
  }
  async getProductAttributes(businessProfileId, filters) {
    let productFilter = filters === undefined || filters === null || filters.length === 0 ?  [] : filters;
    let response = await api.get(`/businessProfiles/${businessProfileId}/productFeeds/products/attributes`,
      {
        params: {
          productFilter
        }
      });
    return response.data.data;
  }
  async getDeltaXAttributes(businessProfileId) {
    let response = await api.get(`/businessProfiles/${businessProfileId}/productFeeds/products/deltax/attributes`);
    return response.data.data;
  }
  async getDeltaXProducts(businessProfileId, pageSize, pageNumber) {
    let response = await api.get(`/businessProfiles/${businessProfileId}/products?pageSize=${pageSize}&pageNumber=${pageNumber}`);
    return response.data.data.products;
  }
  async createTask(businessProfileId, id, productCatalogId, engine, isDirectTask, isApiUploadRequired, productFilters, productMappings, productCount, feedProcessorId) {
    let params = {
      productCatalogId,
      engine,
      isDirectTask,
      isApiUploadRequired
    };
    if(isDirectTask){
      params.productFilters = productFilters === undefined || productFilters === null ?  [] : productFilters;
      params.productMappings = productMappings === undefined || productMappings === null ? [] :  productMappings;
      params.productCount = productCount;
      params.feedProcessorId = feedProcessorId;
    }
    await api.post(`/businessProfiles/${businessProfileId}/productFeeds/${id}/process`, params);
  }
  async getFeedStatus(businessProfileId, id){
    let response = await api.get(`/businessProfiles/${businessProfileId}/productFeeds/${id}/status`);
    return response.data.data;
  }
}
