export const shapes = Object.freeze([
  {
    id: 1,
    name: 'shapes-shape_rectangle_1',
    path: 'M 16 108.5 h 480 v 295 h -480 v -295 z'
  },
  {
    id: 2,
    name: 'shapes-shape_rectangle_2',
    path: 'M 476 403.5 h -440 c -11 0 -20 -9 -20 -20 v -255 c 0 -11 9 -20 20 -20 h 440 c 11 0 20 9 20 20 v 255 c 0 11 -9 20 -20 20 z'
  },
  {
    id: 3,
    name: 'shapes-shape_square_1',
    path: 'M 16 16 h 480 v 480 h -480 z'
  },
  {
    id: 4,
    name: 'shapes-shape_square_2',
    path: 'M 476 496 h -440 c -11 0 -20 -9 -20 -20 v -440 c 0 -11 9 -20 20 -20 h 440 c 11 0 20 9 20 20 v 440 c 0 11 -9 20 -20 20 z'
  },
  {
    id: 5,
    name: 'shapes-shape_circle',
    path: 'M 16 256 a 240 240 0 1 0 480 0 240 240 0 1 0 -480 0'
  },
  {
    id: 6,
    name: 'shapes-shape_oval',
    path: 'M 16 257 a 240 144 0 1 0 480 0 240 144 0 1 0 -480 0'
  },
  {
    id: 7,
    name: 'shapes-shape_triangle_1',
    path: 'M 256 48.5 l 240 415 h -480 z'
  },
  {
    id: 8,
    name: 'shapes-shape_triangle_2',
    path: 'M 496 473.5 h -480 c -3.6 0 -6.9 -1.9 -8.7 -5 -1.8 -3.1 -1.8 -6.9 0 -10 l 240 -415 c 1.8 -3.1 5.1 -5 8.7 -5 s 6.9 1.9 8.7 5 l 240 415 c 1.8 3.1 1.8 6.9 0 10 s -5.1 5 -8.7 5 z'
  },
  {
    id: 9,
    name: 'shapes-shape_triangle_3',
    path: 'M 16 48.5 l 480 415 h -480 z'
  },
  {
    id: 10,
    name: 'shapes-shape_polygon_1',
    path: 'M 256 27.7 l 240 174.4 -91.7 282.2 h -296.6 l -91.7 -282.2 z'
  },
  {
    id: 11,
    name: 'shapes-shape_polygon_2',
    path: 'M 256 15.8 l 208 120.1 v 240.2 l -208 120.1 -208 -120.1 v -240.2 z'
  },
  {
    id: 12,
    name: 'shapes-shape_polygon_3',
    path: 'M 256 22 l 192.5 92.7 47.5 208.3 -133.2 167 h -213.6 l -133.2 -167 47.5 -208.3 z'
  },
  {
    id: 13,
    name: 'shapes-shape_polygon_4',
    path: 'M 256 16 l 169.7 70.3 70.3 169.7 -70.3 169.7 -169.7 70.3 -169.7 -70.3 -70.3 -169.7 70.3 -169.7 z'
  },
  {
    id: 14,
    name: 'shapes-shape_polygon_5',
    path: 'M 256 19.6 l 156.6 57.1 83.4 144.3 -28.9 164.2 -127.7 107.2 h -166.8 l -127.7 -107.2 -28.9 -164.2 83.4 -144.3 z'
  },
  {
    id: 15,
    name: 'shapes-shape_star_1',
    path: 'M 256 406.3 l 148.3 78 -28.3 -165.2 120 -117 -165.8 -24.1 -74.2 -150.3 -74.2 150.3 -165.8 24.1 120 117 -28.3 165.2 z'
  },
  {
    id: 16,
    name: 'shapes-shape_star_2',
    path: 'M 256 136 l 120 -87.8 -16.1 147.8 136.1 60 -136.1 60 16.1 147.8 -120 -87.8 -120 87.8 16.1 -147.8 -136.1 -60 136.1 -60 -16.1 -147.8 z'
  },
  {
    id: 17,
    name: 'shapes-shape_star_3',
    path: 'M 256 126.1 l 99.4 -110.1 -7.6 148.2 148.2 -7.6 -110.1 99.4 110.1 99.4 -148.2 -7.6 7.6 148.2 -99.4 -110.1 -99.4 110.1 7.6 -148.2 -148.2 7.6 110.1 -99.4 -110.1 -99.4 148.2 7.6 -7.6 -148.2 z'
  },
  {
    id: 18,
    name: 'shapes-shape_heart_1',
    path: 'M 16 187.8 c 0 88.4 79.9 151.5 140 199 62.5 49.4 80 60 100 80 20 -20 36.7 -31.6 100 -80 61 -46.6 140 -111.6 140 -200 0 -129.8 -160 -200 -240 -80 -80 -119.9 -240 -49.7 -240 81 z'
  },
  {
    id: 19,
    name: 'shapes-shape_heart_2',
    path: 'M 16 170.1 c 0 82.9 74.9 142 131.3 186.6 58.6 46.3 90 71.3 108.7 90 18.8 -18.8 49.4 -44.7 108.7 -90 57.2 -43.7 131.3 -104.6 131.3 -187.5 0 -121.7 -165 -142.5 -240 -30 -75 -112.5 -240 -91.7 -240 30.9 z'
  },
  {
    id: 20,
    name: 'shapes-shape_badge_1',
    path: 'M 257.3 16.2 s -148 58.2 -204.4 81.4 c 0 75.7 -16.8 303.5 204.4 398.2 218.7 -94.6 201.9 -322.4 201.9 -398.2 -62.1 -23.6 -201.9 -81.4 -201.9 -81.4 z'
  },
  {
    id: 21,
    name: 'shapes-shape_badge_2',
    path: 'M 18.9 29.4 c 0 85.5 -47.1 453.2 237.1 453.2 s 237.1 -367 237.1 -453.2 h -474.2 z'
  },
  {
    id: 22,
    name: 'shapes-shape_badge_3',
    path: 'M 16 20.8 v 288.9 l 240 181.5 240 -181.5 v -288.9 h -480 z'
  },
  {
    id: 23,
    name: 'shapes-shape_badge_4',
    path: 'M 129.6 441.7 c -30.3 -15.1 -59 -29.3 -109.1 -29.3 -2.5 0 -4.5 -2 -4.5 -4.5 v -303.8 c 0 -2.5 2 -4.5 4.5 -4.5 50.1 0 78.8 -14.2 109.1 -29.3 32.2 -16 65.5 -32.5 126.3 -34.3 61 1.8 94.3 18.4 126.5 34.3 30.3 15.1 59 29.3 109.1 29.3 2.5 0 4.5 2 4.5 4.5 v 303.8 c 0 2.5 -2 4.5 -4.5 4.5 -50.1 0 -78.8 14.2 -109.1 29.3 -32.2 16 -65.5 32.5 -126.4 34.4 -60.9 -1.9 -94.2 -18.4 -126.4 -34.4 z'
  },
  {
    id: 24,
    name: 'shapes-shape_callout_1',
    path: 'M 256.5 496 c -33.9 0 -65.6 -21.1 -77.3 -51.4 -5 -12.2 -7 -25.5 -5.9 -39.3 -10.1 7.5 -21.1 12.4 -32.7 14.5 -4.3.9 -8.8 1.3 -13.3 1.3 -28.3 0 -55.3 -16.4 -70.6 -42.8 v .1 c -18.1 -30.6 -16.9 -70.3 3 -96.6 7.7 -10.7 18.2 -19.3 30.8 -25.3 -11.8 -5.3 -21.8 -12.9 -29.6 -22.5 -21.1 -25 -24.1 -64.3 -7.1 -95.6 14.9 -28.1 43.5 -46.3 73.1 -46.3 3.3 0 6.6 .2 9.8 .7 12.7 1.7 24.7 6.5 35.7 14.3 -1.3 -13 0 -25.4 4.1 -37 10.3 -31.1 41.8 -53.4 76.7 -54.1 h 2.3 c 33.9 0 65.6 21.1 77.4 51.4 5 12.2 7 25.4 5.9 39.3 10.1 -7.5 21.1 -12.4 32.7 -14.5 4.3 -.9 8.8 -1.3 13.3 -1.3 28.3 0 55.3 16.4 70.6 42.8 v -.1 c 18.1 30.6 16.9 70.2 -3 96.5 -7.8 10.8 -18.2 19.3 -30.9 25.3 11.9 5.3 21.8 12.9 29.6 22.5 21.1 25 24.1 64.2 7.1 95.5 -14.8 28.1 -43.5 46.3 -73.1 46.3 -3.3 0 -6.6 -.2 -9.7 -.7 -12.7 -1.7 -24.7 -6.5 -35.7 -14.3 1.3 13 0 25.4 -4.1 36.9 -10.2 31.3 -41.8 53.7 -76.8 54.3 -.7 .1 -1.5 .1 -2.3 .1 z'
  },
  {
    id: 25,
    name: 'shapes-shape_callout_2',
    path: 'M 252.9 496 c -45.5 0 -62.6 -50.7 -59.6 -88.3 -26.5 31.6 -71.7 48.3 -105.6 18.8 -36.3 -31.5 -13.4 -81.7 16.9 -107.6 -42.4 3 -84.5 -16.4 -88.4 -62.3 -4 -47.4 50.1 -66.3 88.2 -63.3 -31.8 -26.5 -48.1 -71.7 -18.8 -105.6 31.3 -36.4 81.8 -13.3 107.6 16.9 -2.7 -39.6 16.4 -88.5 62.4 -88.6 47.2 -.1 66.4 47.6 63.2 88.4 27.2 -31.3 71 -48.3 105.6 -18.8 36.6 31.2 13.2 82 -17 107.7 42.4 -3.2 84.5 16.4 88.4 62.2 4 47.3 -50 66.3 -88.2 63.3 31.2 27.1 47.9 70.1 19.3 104.9 -30.8 37.5 -82.2 14.2 -108.1 -16.2 3.3 42.5 -18.1 88.5 -65.9 88.5 z'
  },
  {
    id: 26,
    name: 'shapes-shape_callout_3',
    path: 'M 256 463.8 l -50.2 30.9 -33.2 -48.7 -58.4 7.9 -10.6 -58 -56.6 -16.6 13.9 -57.3 -44.9 -38.1 36 -46.7 -25.5 -53.1 51.9 -28 -1.7 -58.9 58.7 -4.5 22.4 -54.5 55.6 19.8 42.6 -40.7 42.6 40.7 55.6 -19.8 22.4 54.5 58.7 4.5 -1.7 58.9 51.9 28 -25.5 53.1 36 46.7 -44.9 38.1 13.9 57.3 -56.6 16.6 -10.6 58 -58.4 -7.9 -33.2 48.7 z'
  },
  {
    id: 27,
    name: 'shapes-shape_chat_bubble_1',
    path: 'M 256 99.7 c -132.6 0 -240 52.1 -240 116.4 0 37.8 37.2 71.4 94.9 92.7 -8.5 30.7 -20.8 61.7 -39.8 103.5 22 -13.7 78.4 -55.9 109.6 -85.7 23.7 3.8 49 5.9 75.3 5.9 132.5 0 240 -52.1 240 -116.4 0 -64.3 -107.5 -116.4 -240 -116.4'
  },
  {
    id: 28,
    name: 'shapes-shape_chat_bubble_2',
    path: 'M 448.7 32.9 h -385.4 c -26 0 -47.3 21.3 -47.3 47.3 v 240.7 c 0 26 21.3 47.3 47.3 47.3 h 275.1 c -16.6 41.4 -36.6 72.7 -82.4 110.9 0 0 99.3 -30.3 157.4 -110.9 h 35.3 c 26 0 47.3 -21.3 47.3 -47.3 v -240.7 c 0 -26 -21.3 -47.3 -47.3 -47.3 z'
  },
  {
    id: 29,
    name: 'shapes-shape_line',
    path: 'M 16 246 h 480 v 20 h -480 v -20'
  },
  {
    id: 30,
    name: 'shapes-shape_arrow_1',
    path: 'M 16 248.4 v 14.9 h 447.5 l -93.2 82.5 11.9 10.5 113.8 -100.2 -113.6 -100.4 -11.8 10.5 92.9 82.2 z'
  },
  {
    id: 31,
    name: 'shapes-shape_arrow_2',
    path: 'M 496 256 l -118.6 -66 v 40.8 h -361.4 v 50.4 h 361.4 v 40.8 l 118.6 -66 z'
  },
  {
    id: 32,
    name: 'shapes-shape_arrow_3',
    path: 'M 294.9 16.4 l 15.7 42.2 c -171.4 70.3 -294 242.3 -289.1 437.4 l 14.7 -1 c 9.1 -.6 18.1 -1.2 27.1 -1.9 l 14.7 -1 c -4.3 -170.1 102.5 -320 252 -381.3 l 15.7 42.2 c 34.7 -40.5 83.1 -76.6 144.8 -99.8 -58.1 -26.2 -124.9 -39.6 -195.6 -36.8 z'
  }
])

export const zeroHeightShapes = [29];

export const nativeShapeTypes = Object.freeze({
  Circle: 'circle',
  Rectangle: 'rect',
  Ellipse: 'ellipse',
  Triangle: 'triangle',
  Polygon: 'polygon',
  Line: 'line'
});

export const nativeShapes = Object.freeze([
  {
    id: 33,
    name: 'shapes-native_shape_rectangle',
    path: 'M 476 403.5 h -440 c -11 0 -20 -9 -20 -20 v -255 c 0 -11 9 -20 21 -20 h 440 c 11 0 20 9 20 20 v 255 c 0 11 -9 20 -20 20 z',
    type: nativeShapeTypes.Rectangle
  },
  {
    id: 34,
    name: 'shapes-native_shape_circle',
    path: 'M 16 256 a 240 240 0 1 0 481 0 240 240 0 1 0 -480 0',
    type: nativeShapeTypes.Circle
  },
  {
    id: 35,
    name: 'shapes-native_shape_oval',
    path: 'M 16 257 a 241 144 0 1 0 480 0 240 144 0 1 0 -480 0',
    type: nativeShapeTypes.Ellipse
  },
  {
    id: 38,
    name: 'shapes-native_shape_line',
    path: 'M 16 246 h 480 v 20 h -481 v -20',
    type: nativeShapeTypes.Line
  },
  {
    id: 36,
    name: 'shapes-native_shape_triangle',
    path: 'M 256 48.5 l 240 416 h -480 z',
    type: nativeShapeTypes.Triangle
  },
  {
    id: 37,
    name: 'shapes-native_shape_polygon',
    path: 'M 256 27.7 l 241 174.4 -91.7 282.2 h -296.6 l -91.7 -282.2 z',
    type: nativeShapeTypes.Polygon
  }
])

export const supportedShapeStyles = {
  circle: ['angle', 'fill', 'fillGradient', 'isFillGradient', 'opacity', 'originX', 'originY', 'radius', 'scaleX', 'scaleY', 'stroke', 'strokeWidth', 'top', 'left'],
  rect: ['angle', 'fill', 'fillGradient', 'isFillGradient', 'opacity', 'originX', 'originY', 'scaleX', 'scaleY', 'stroke', 'strokeWidth', 'top', 'left', 'height', 'width', 'rx', 'ry'],
  triangle: ['angle', 'fill', 'fillGradient', 'isFillGradient', 'opacity', 'originX', 'originY', 'scaleX', 'scaleY', 'stroke', 'strokeWidth', 'top', 'left', 'height', 'width'],
  ellipse: ['angle', 'fill', 'fillGradient', 'isFillGradient', 'opacity', 'originX', 'originY', 'scaleX', 'scaleY', 'stroke', 'strokeWidth', 'top', 'left', 'rx', 'ry'],
  polygon: ['angle', 'fill', 'fillGradient', 'isFillGradient', 'opacity', 'originX', 'originY', 'scaleX', 'scaleY', 'stroke', 'strokeWidth', 'top', 'left'],
  line: ['angle', 'fill', 'fillGradient', 'isFillGradient', 'opacity', 'originX', 'originY', 'scaleX', 'scaleY', 'stroke', 'strokeWidth', 'top', 'left'],
  path: ['angle', 'fill', 'fillGradient', 'isFillGradient', 'opacity', 'originX', 'originY', 'scaleX', 'scaleY', 'stroke', 'strokeWidth', 'top', 'left']
}

export const nativeShapeDefaults = {
  rectangle: {
    rx: 0,
    ry: 0,
    height: 120,
    width: 160
  },
  circle: {
    radius: 60
  },
  ellipse: {
    rx: 80,
    ry: 50
  },
  triangle: {
    height: 130,
    width: 130
  },
  polygon: {
    points: [
      { x: 90, y: 20 },
      { x: 20, y: 60 },
      { x: 45, y: 140 },
      { x: 140, y: 140 },
      { x: 160, y: 60 }
    ]
  },
  line: {
    coordinates: [0, 0, 130, 0]
  }
}