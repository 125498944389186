import { globalBlendModes } from '../constants/filters';

export const applyBlend = (fabricObject, filters) => {
  if (filters && filters.globalBlend && filters.globalBlend.enabled) {
    fabricObject.set('globalCompositeOperation', filters.globalBlend.operation);
  }
  else {
    // Default mode
    fabricObject.set('globalCompositeOperation', globalBlendModes.SOURCE_OVER.value);
  }
}