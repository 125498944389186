// dashboard components
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ 'Features/dashboard/Main');

const dashboardRoute =  {
  name: 'dashboard',
  path: 'dashboard',
  component: Dashboard,
  meta: {
    requiresAuth: true,
    title: 'message.dashboard',
    breadcrumb: 'message.dashboard',
    showDateRangeFilter: false
  }
}

const portalDashboardRoute =  {
  name: 'portal-dashboard',
  path: '/dashboard',
  component: Dashboard,
  meta: {
    requiresAuth: true,
    title: 'message.dashboard',
    breadcrumb: 'message.dashboard',
    showDateRangeFilter: false
  }
}

export const DashboardRoute = dashboardRoute;
export const PortalDashboardRoute = portalDashboardRoute;