const postbackUrls = () => import(/* webpackChunkName: "postbackUrl" */'./Main');

const postbackUrlRoute = {
  name: 'postbackUrl',
  path: 'pixel',
  component: postbackUrls,
  meta: {
    requiresAuth: true,
    title: 'message.postbackUrl.postbackUrls',
    breadcrumb: 'message.postbackUrl.postbackUrls'
  }
};

export const PostbackUrlRoute = postbackUrlRoute;