const publishedReports = () => import(/* webpackChunkName: "publishedReports" */ 'Features/publishedReports/Main');

const publishedReportRoute =  {
  name: 'publishedReports',
  path: 'publishedReports',
  component: publishedReports,
  meta: {
    requiresAuth: true,
    title: 'message.reports',
    breadcrumb: 'message.reports'
  }
}
const portalPublishedReportRoute = {
  name: 'portal-publishedReports',
  path: '/publishedReports',
  component: publishedReports,
  meta: {
    requiresAuth: true,
    title: 'message.reports',
    breadcrumb: 'message.reports'
  }
}

export const PublishedReportRoute = publishedReportRoute;
export const PortalPublishedReportRoute = portalPublishedReportRoute;