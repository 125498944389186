import Vue from 'vue'
import Vuex from 'vuex'

import settings from './modules/settings';
import sidebar from './modules/sidebar';
import dateRangeSettings from './modules/dateRangeSettings';
import auth from './modules/auth';
import appLoader from './modules/appDialogLoader';
import payment from 'Features/wallet/store';
import roimaticConfiguration from './modules/roimaticConfiguration';
import featureSettings from './modules/featureSettings';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    settings,
    sidebar,
    dateRangeSettings,
    appLoader,
    payment,
    roimaticConfiguration,
    featureSettings
  }
})
