const  LocalBusiness = () => import(/* webpackChunkName: "localBusiness" */ 'Features/localBusiness/Main');

export const LocalBusinessRoute = {
  name: 'localBusiness',
  path: 'localBusiness',
  component: LocalBusiness,
  meta: {
    requiresAuth: true,
    title: 'message.localBusiness'
  }
}

