const Briefs = () => import(/* webpackChunkName: "dashboard" */ 'Features/briefs/Main');
const briefRoute =  {
  name: 'portal-brief',
  path: '/brief/create',
  component: Briefs,
  meta: {
    requiresAuth: true,
    title: 'Briefs',
    breadcrumb: 'Briefs',
    showDateRangeFilter: false,
    customLayout: true
  }
};

const briefRouteEdit =  {
  name: 'briefEdit',
  path: 'brief/edit/:briefId',
  component: Briefs,
  meta: {
    requiresAuth: true,
    title: 'Briefs',
    breadcrumb: 'Briefs',
    showDateRangeFilter: false,
    customLayout: true,
    isBackBtnEnabled: true
  }
}
export const BriefRoute = briefRoute;
export const BriefRouteEdit = briefRouteEdit;