
const UserEmailVerification = () => import(/* webpackChunkName: "userEmailVerification" */ './Main');

const emailVerification =
{
  name: 'userEmailVerification',
  path: '/verify',
  component: UserEmailVerification,
  meta: {
    requiresAuth: true
  }
};

export const UserEmailVerificationRoute = emailVerification;