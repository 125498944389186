/**
 * Vuely Global Components
 */
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import AppSectionLoader from 'Components/AppSectionLoader/AppSectionLoader';

// page title bar
import PageTitleBar from 'Components/PageTitleBar/ThePageTitleBar';

// App Card component
import AppCard from 'Components/AppCard/AppCard';

const GlobalComponents = {
  install(Vue) {
    Vue.component('AppCard', AppCard);
    Vue.component('VuePerfectScrollbar', VuePerfectScrollbar);
    Vue.component('PageTitleBar', PageTitleBar);
    Vue.component('AppSectionLoader', AppSectionLoader);
  }
};

export default GlobalComponents;
