const OrganicPromotionLaunchWizard = () =>
  import(/* webpackChunkName: "OrganicPromotionLaunch" */ './Main');

const organicPromotionLaunchRoute = {
  name: 'OrganicPromotionLaunch',
  path: 'promotions/:promotionId/organicPromotionLaunchWizard',
  component: OrganicPromotionLaunchWizard,
  meta: {
    requiresAuth: true,
    title: 'Organic Promotion Launch',
    breadcrumb: 'Publish Post',
    isBackBtnEnabled: true
  }
};

export const OrganicPromotionLaunchRoute = organicPromotionLaunchRoute;