// router

import { AccountSelectorRoute, LoginRoute, ProfileSelectorRoute } from './features/login/router';

import { AppService } from 'AppServices/appService';
import { AuthService } from 'AppServices/authService';
import { BrandPortalHelper } from 'Helpers/brandPortalHelper'
import { BriefRoute } from 'Features/briefs/router.js';
import { BusinessProfilesHelper } from 'Helpers/businessProfilesHelper';
import { DashboardAffiliateRoute } from './router/affiliateRouter';
import { EmbedRouteObjects } from 'Features/embed/router'
import { HqLocalApiService } from 'AppServices/hqLocalApiService';
import { OnboardingRoute } from './features/onboarding/constants';
import { PageNotFoundRoute } from './router/pageNotFound';
import { PortalDashboardRoute } from 'Features/dashboard/router';
import { PortalPublishedReportRoute } from 'Features/publishedReports/router';
import { RoimaticService } from 'AppServices/roimaticService';
import { UserEmailVerificationRoute } from 'Features/userEmailVerification/router';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { getBpseIdsForAffiliateUser } from 'Helpers/affiliateUserHelper';
import { getPortalDomain } from './store/modules/auth/index.js';
import { isJson } from 'Helpers/helpers'
import router from './router';

// onboarding routes
const { getPortalData } = new AuthService();
const { getCurrencyInfo, getBusinessProfileById, getPortalBusinessProfiles } = new AppService();

Vue.use(VueCookies);
const  { getBusinessProfiles } = new BusinessProfilesHelper();

const nonAppLevelRoutesWithBpId = [
  OnboardingRoute.KYCVerification.name,
  OnboardingRoute.Assets.name,
  AccountSelectorRoute.name
];
const portalLevelRoutes = [
  BriefRoute.name,
  PortalDashboardRoute.name,
  PortalPublishedReportRoute.name
]

const isBpLevelRoute = (destinationRoute) => {
  return (
    destinationRoute.path.startsWith('/app') ||
        nonAppLevelRoutesWithBpId.includes(destinationRoute.name)
  );
};
const isPortalLevelRoute = (destinationRoute) => {
  return portalLevelRoutes.includes(destinationRoute.name);
};
// checks whether account is valid or not from bp-bpse mappings
const isAffiliateAccountValid = (user, businessProfileId, accountId) => {
  var businessProfileBpses = JSON.parse(user.businessProfileBpses).find(c => c.coreBusinessProfileId == businessProfileId);
  return businessProfileBpses.businessProfileSearchEngineIds.includes(parseInt(accountId));
}

const getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieParts = cookie.split('=');
    if (cookieParts.length === 2 && cookieParts[0] === name) {
      return decodeURIComponent(cookieParts[1]);
    }
  }
  return null;
}

export const GlobalRouterGuard = {
  configure(nProgress, i18n, store) {
    const redirect = (from, redirectRoute, next) => {
      // Call NProgress.done() for cancelled navigations
      if (from.name === redirectRoute.name) {
        nProgress.done();
      }
      next(redirectRoute);
    };

    // navigation guards before each
    router.beforeEach(async (to, from, next) => {
      nProgress.start();
      // We do not want roimatic router guard logic on navigation between every CB route
      if (to.name
        && to.name.startsWith('creativeBuilder')
        && from.name
        && from.name.startsWith('creativeBuilder')
        && (to.params.businessProfileId == from.params.businessProfileId)
      ) {
        next();
        nProgress.done();
        return;
      }

      await store.dispatch('setHeaderAddtionalInfo', null);
      await store.dispatch('setEnableBackButton', false);

      if(to.name == PageNotFoundRoute.name){
        next();
        return;
      }

      await store.dispatch('setPortal');
      if(!store.state.auth.portal){
        let redirectRoute = {
          name: PageNotFoundRoute.name,
          params: [from.path], // https://github.com/vuejs/vue-router/issues/724#issuecomment-663315290
          replace: true
        };
        redirect(from, redirectRoute, next);
      }
      // Out of the matched routes find the title in the meta of the closest route,
      // The closest route is the last route in a nested route
      var nearestRouteMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

      let portalTitle = 'Roimatic';
      if(store.state.auth.portal
        && store.state.auth.portal.portalDefaultConfig)
      {
        portalTitle =  JSON.parse(store.state.auth.portal.portalDefaultConfig).title || 'Roimatic';
      }

      document.title = nearestRouteMeta
        ? `${portalTitle} | ${i18n.t(nearestRouteMeta.meta.title)}`
        : portalTitle;

      // Setting bpId as null to all non bp level routes
      if (!isBpLevelRoute(to)) {
        await store.dispatch('setBusinessProfileId', null);
      }

      /**
       * If current portal is a creative builder portal and page is being redirected to signup or store selection page,
       * then redirect instead to signup user page since the setup only involves sign up user and enter store details.
      */
      if ((to.name == OnboardingRoute.StoreSelection.name || to.name == OnboardingRoute.Signup.name)  && store.getters.portal.isCreativeBuilderPortal) {
        let redirectRoute = {
          name: OnboardingRoute.SignupUser.name,
          query: { isCreativeBuilder: true }
        };
        redirect(from, redirectRoute, next);
      }

      if ((to.name == OnboardingRoute.StoreSelection.name || to.name == OnboardingRoute.Signup.name)  && store.getters.portal.isRichMediaAdBuilderPortal) {
        let redirectRoute = {
          name: OnboardingRoute.SignupUser.name,
          query: { isRichMediaAdBuilder: true }
        };
        redirect(from, redirectRoute, next);
      }

      // KYC page is also used for profile setup for goby, therefore auth needs to be skipped if token is provided in the parameter
      if (to.matched.some((record) => record.meta.requiresAuth) && !(to.name === OnboardingRoute.KYCVerification.name && to.query.token)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        let user = JSON.parse(localStorage.getItem('user'));
        let accessToken = localStorage.getItem('accessToken');
        if(!user || !accessToken){
          // check whether user is logged in using cookies from VB
          let accessTokenCookie = Vue.$cookies.get('accessToken');
          let userCookie = Vue.$cookies.get('user');
          if (accessTokenCookie) {
            accessToken = accessTokenCookie.accessToken;
            localStorage.setItem('accessToken', accessToken);
          }
          if (userCookie) {
            user = userCookie;
            const portalDomain = getPortalDomain();
            const portalData = await getPortalData(portalDomain);
            const businessProfiles = await getPortalBusinessProfiles(user.id, portalData.id);
            user.businessProfiles = businessProfiles;
            user.isSignedin = true
            localStorage.setItem('user', JSON.stringify(user));
            store.dispatch('setUserData', user);
          }
        }
        if (
          !user || !accessToken ||
                    (!to.path.startsWith('/app') &&
                        !(to.name == ProfileSelectorRoute.name) &&
                        !(to.name == AccountSelectorRoute.name) &&
                        !to.path.startsWith(OnboardingRoute.Signup.path) &&
                        !to.path.startsWith(UserEmailVerificationRoute.path) &&
                        !(to.path === '/localBusiness') &&
                        !isPortalLevelRoute(to))
        ) {
          if (!accessToken) {
            // removing user if accesstoken is missing to prevent endless redirect
            localStorage.removeItem('user');
          }

          let redirectRoute = {
            name: LoginRoute.name,
            query: { redirect: to.fullPath }
          };
          redirect(from, redirectRoute, next);
        } else if (isBpLevelRoute(to)) {
          /// if businessProfileId parameter is missing and the destination is KYC verification,
          // then this scenario is for 'Other Store' or 'Goby (embed)' set up
          if (
            !to.params.businessProfileId &&
                        to.name == OnboardingRoute.KYCVerification.name
          ) {
            var isEmbedRoute = from.name == EmbedRouteObjects.EmbedLoginRoute.name || Boolean(localStorage.getItem('embedLoginDetails'));
            // In case of 'Other Store' setup if the previous route is not the OtherStore details page,
            // Or in case of Goby embed if the embed login
            // then redirect back to previous page or signup
            if (from.name == OnboardingRoute.OtherStore.name || isEmbedRoute){
              next();
              return;
            }
            else {
              let redirectRoute = {
                name: from.name
                  ? from.name
                  : OnboardingRoute.Signup.name
              };
              redirect(from, redirectRoute, next);
              return;
            }
          }
          let selectedBusinessProfile;
          let isBpIdInValid = isNaN(to.params.businessProfileId);

          if (!isBpIdInValid) {
            // /app is the AppLayout hence it has to include businessProfileId in the path
            let businessProfileId = parseInt(
              to.params.businessProfileId
            );
            selectedBusinessProfile = user.businessProfiles.find(
              (bp) => bp.id === businessProfileId
            );
          }

          if (!selectedBusinessProfile) {
            let redirectRoute = {
              name: PageNotFoundRoute.name
            };
            redirect(from, redirectRoute, next);
            return;
          }
          // Get roimatic configuration for the businessProfile
          let roimaticService = new RoimaticService(
            selectedBusinessProfile.id
          );
          let roimaticConfiguration;
          let portal = JSON.parse(localStorage.getItem('portal'));
          if(portal.isBrandPortal){
            var brandPortalHelper = new BrandPortalHelper();
            roimaticConfiguration = await brandPortalHelper.setupRoimaticConfig();
          }
          else{
            roimaticConfiguration = await roimaticService.getRoimaticConfiguration();
            await store.dispatch(
              'setRoimaticConfiguration',
              roimaticConfiguration
            );
          }

          let businessProfile = await getBusinessProfileById(selectedBusinessProfile.id);
          if(businessProfile && businessProfile.facebookFeatureFlags) {
            let facebookFeatureFlags = JSON.parse(businessProfile.facebookFeatureFlags);
            if(facebookFeatureFlags) {
              await store.dispatch(
                'setBusinessProfileFacebookFeatureFlags',
                facebookFeatureFlags
              );
            }
          }
          await store.dispatch(
            'setBusinessProfileId',
            selectedBusinessProfile.id
          );

          let hqLocalApiService = new HqLocalApiService(selectedBusinessProfile.id);
          let hubSettings = await hqLocalApiService.getSettingsAsync('Hub');
          let bpSettings = await hqLocalApiService.getSettingsAsync('BusinessProfile');
          let settings = [...hubSettings, ...bpSettings];
          store.dispatch('setBusinessProfileSettings', settings);

          // for affiliate user
          var businessProfileBpses = isJson(user.businessProfileBpses) && JSON.parse(user.businessProfileBpses)
          if (portal.isAffiliate && businessProfileBpses && businessProfileBpses.length > 0) {
            var accountId = to.params.accountId;

            /*case when account id is not present in params and
              page is not redirected to account selection or profile selection page.*/
            if (to.name != AccountSelectorRoute.name && to.name != ProfileSelectorRoute.name && !accountId){
              var redirectRoute = {}
              var bpseIds = getBpseIdsForAffiliateUser(businessProfileBpses, selectedBusinessProfile.id);
              if (bpseIds && bpseIds.length == 1) {
                await store.dispatch(
                  'setBusinessProfileSearchEngineId',
                  bpseIds[0]
                );
                user.businessProfileSearchEngineId = bpseIds[0];
                await store.dispatch('setUser', user);
                redirectRoute = {
                  name: DashboardAffiliateRoute.name,
                  params: { businessProfileId: selectedBusinessProfile.id, accountId: bpseIds[0] }
                }
              }
              else {
                redirectRoute = {
                  name: AccountSelectorRoute.name,
                  params: { businessProfileId: selectedBusinessProfile.id }
                };
              }
              redirect(from, redirectRoute, next);
              return;
            }

            // case when account id is present in url and it is invalid.
            else if(accountId && !isAffiliateAccountValid(user, selectedBusinessProfile.id, accountId)) {
              var redirectRoute = {
                name: AccountSelectorRoute.name,
                params: { businessProfileId: selectedBusinessProfile.id }
              };
              redirect(from, redirectRoute, next);
              return;
            }

            // case when accound id is present and it is valid.
            else if (
              accountId &&
              isAffiliateAccountValid(user, selectedBusinessProfile.id, accountId)) {
              await store.dispatch(
                'setBusinessProfileSearchEngineId',
                accountId
              );
              user.businessProfileSearchEngineId = accountId;
              await store.dispatch('setUser', user);
            }
          }
          if (selectedBusinessProfile.currencyId == 0 || !selectedBusinessProfile.currencyId)
          {
            var businessProfiles = await getBusinessProfiles(true);
            user.businessProfiles = businessProfiles;
            selectedBusinessProfile = businessProfiles.find(bp => bp.id == selectedBusinessProfile.id);
          }
          let currencyInfo = await getCurrencyInfo(
            selectedBusinessProfile.currencyId,
            selectedBusinessProfile.id
          );
          await store.dispatch('setCurrencyInfo', currencyInfo);
          await store.dispatch('setCurrencyCode', currencyInfo.code);
          await store.dispatch('setTimezoneId', selectedBusinessProfile.timezoneId);
          let isAutoFlavour = store.getters.isAutoFlavour;
          if(isAutoFlavour){
            let hqLocalApiService = new HqLocalApiService(selectedBusinessProfile.id);
            let hqFranchiseStores = await hqLocalApiService.getStores(user.id);
            await store.dispatch(
              'setHqFranchiseStores',
              hqFranchiseStores
            );
          }
          if (nonAppLevelRoutesWithBpId.includes(to.name)) {
            // In case of KYC and Assets, route based on onboardingState
            // If not in the right state then redirect to signup
            if (
              to.name == OnboardingRoute.KYCVerification.name &&
                            roimaticConfiguration.onboardingState == 3
            )
              next();
            else if (
              to.name == OnboardingRoute.Assets.name &&
                            (roimaticConfiguration.onboardingState < 3 ||
                                to.query.code)
            )
              next();
            else if (
              to.name == AccountSelectorRoute.name
            )
              next();
            else {
              let redirectRoute = {
                name:
                                    from.name &&
                                    from.name !=
                                        OnboardingRoute.KYCVerification.name
                                      ? from.name
                                      : OnboardingRoute.Signup.name
              };
              redirect(from, redirectRoute, next);
            }
            return;
          }
          // Ensure user has completed remaining onboarding steps
          // after BP setup
          try {
            let redirectRoute = null;
            switch (roimaticConfiguration.onboardingState) {
              case 2:
                redirectRoute = {
                  name: OnboardingRoute.Assets.name,
                  params: {
                    businessProfileId:
                  selectedBusinessProfile.id
                  }
                };
                redirect(from, redirectRoute, next);
                break;
              case 3:
                redirectRoute = {
                  name: OnboardingRoute.KYCVerification.name,
                  params: {
                    businessProfileId:
                  selectedBusinessProfile.id
                  }
                };
                redirect(from, redirectRoute, next);
                break;
              default:
                next();
            }
          } catch {
            // If error occurs while parsing roimaticConfiguration
            let redirectRoute = {
              name: LoginRoute.name
            };
            redirect(from, redirectRoute, next);
          }
        }
        else if (isPortalLevelRoute(to)){
          let portal = JSON.parse(localStorage.getItem('portal'));
          if(portal.isBrandPortal){
            await store.dispatch(
              'setBusinessProfileId',
              -1
            );
            var brandPortalHelper = new BrandPortalHelper();
            await brandPortalHelper.setupRoimaticConfig();
            next();
          }
        }
        else {
          next();
        }
      }
      else if (to.path.startsWith('/cbRenderer') && process.env.NODE_ENV !== 'development') {
        let redirectRoute = {
          name: PageNotFoundRoute.name,
          params: [from.path],
          replace: true
        };
        const puppeteerSecretKey = getCookie('puppeteerSecretKey');
        if(puppeteerSecretKey !== process.env.VUE_APP_PUPPETEER_SECRET_KEY) {
          redirect(from, redirectRoute, next);
        }
        next();
      }
      // else if (
      //     to.path.startsWith("/home") &&
      //     localStorage.getItem("user") !== null &&
      //     localStorage.getItem("accessToken") !== null
      //   ) {
      //     next({
      //       name: LoginRoute.name
      //     });
      //   }
      else {
        next(); // make sure to always call next()!
      }
    });

    // navigation guard after each
    router.afterEach(() => {
      nProgress.done();
      setTimeout(() => {
        const contentWrapper = document.querySelector(
          '.v-content__wrap'
        );
        if (contentWrapper !== null) {
          contentWrapper.scrollTop = 0;
        }
        const boxedLayout = document.querySelector(
          '.app-boxed-layout .app-content'
        );
        if (boxedLayout !== null) {
          boxedLayout.scrollTop = 0;
        }
        const miniLayout = document.querySelector(
          '.app-mini-layout .app-content'
        );
        if (miniLayout !== null) {
          miniLayout.scrollTop = 0;
        }
      }, 200);
    });

    router.onError(error => {
      if (/loading chunk \w* failed./i.test(error.message)) {
        var queryParameters = new URLSearchParams(window.location.search);
        // The retry query parameter is added to prevent endless reload which might happen in certain cases
        var retry = queryParameters.get('retry');
        if(!(retry == 'true')){
          queryParameters.append('retry', 'true');
          window.location.assign(window.location.origin + window.location.pathname + `?${queryParameters}`);
        }
      }
    })
  }
};