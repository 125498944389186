const state = {
  showDialog: false,
  width: 500,
  message: ''
}

const getters = {
  showDialog: state=> state.showDialog,
  width: state=>state.width,
  message: state=>state.message
}

const actions = {
  showLoader(context, payload) {
    context.commit('showLoader', payload);
  },
  hideLoader(context){
    context.commit('hideLoader');
  }
}

const mutations = {
  showLoader(state, payload) {
    state.width = payload.width || 500;
    state.message = payload.message;
    state.showDialog = true;
  },
  hideLoader(state){
    state.message = '';
    state.width = 500;
    state.showDialog = false;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}