const InviteUser = () => import(/* webpackChunkName: "inviteUser" */ 'Features/inviteUser/InviteUser');

const inviteUser = {
  name: 'inviteUser',
  path: '/inviteUser',
  component: InviteUser,
  meta: {
    requiresAuth: false
  }
}

export const InviteUserRoute = inviteUser;
