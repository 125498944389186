<template>
  <div class="flex" :class="[containerClasses]">
    <div class="app-card" :class="[customClasses, { 'd-none': close }]">
      <app-card-heading v-if="header" :custom-classes="headerClasses">
        <slot name="header" />
      </app-card-heading>
      <app-card-content :extra-class="[contentCustomClass]" :full-block="fullBlock">
        <slot name="body" />
      </app-card-content>
      <app-card-footer v-if="footer" :custom-classes="footerClasses">
        <slot name="footer" />
      </app-card-footer>
      <app-section-loader :status="reload" />
    </div>
  </div>
</template>

<script>
import AppCardHeading from './AppCardHeading';
import AppCardFooter from './AppCardFooter';
import AppCardContent from './AppCardContent';
import AppSectionLoader from '../AppSectionLoader/AppSectionLoader';

export default {
  props: [
    'header',
    'colClasses',
    'customClasses',
    'fullBlock',
    'contentCustomClass',
    'closeable',
    'reloadable',
    'fullScreen',
    'footer',
    'withTabs',
    'tabs',
    'onChangeTabCallback',
    'headerClasses',
    'footerClasses',
    'containerClasses'
  ],
  data() {
    return {
      reload: false,
      close: false
    };
  },
  components: {
    AppCardHeading,
    AppCardFooter,
    AppCardContent,
    AppSectionLoader
  },
  methods: {
    onCollapse() {
      this.$refs['fullscreen'].toggle();
    },
    onReload() {
      this.reload = true;
      let self = this;
      setTimeout(() => {
        self.reload = false;
      }, 1500);
    },
    onClose() {
      this.close = true;
    },
    onChangeTab(value) {
      this.$emit('onChangeTabCallback', value);
    }
  }
};
</script>