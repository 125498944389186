<template>
  <div class="app-card-content" :class="[extraClass]">
    <slot />
  </div>
</template>

<script>
export default {
  props: ['extraClass', 'fullBlock']
};
</script>
