const hourToMillisecondFactor = 3600000;
const minuteToMillisecondFactor = 60000;
const secondToMillisecondFactor = 1000;

/**
 * Truncates a string to a specified length
 * @param {string} str the string to truncate
 * @param {number} length the length of the string to truncate
 * @param {string} ending the ending of the truncated string
 * @returns the truncated string
 */
export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export const isValidUrl = (url) => {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  return regex.test(url);
}

/**
 * Formats the time from 'hh:mm:ss.xxx' to total milliseconds
 * @param {string} time input time to convert
 * @returns formatted time
 */
export const formatTimeToMilliseconds = (time) => {
  if (!time) {
    return;
  }
  const duration = time.split(':');
  const durationInMilliseconds = Number(duration[0]) * hourToMillisecondFactor + Number(duration[1]) * minuteToMillisecondFactor + Number(duration[2]) * secondToMillisecondFactor;
  return durationInMilliseconds;
};

/**
 * Formats the time from milliseconds to 'hh:mm:ss.xxx'
 * @param {string} time input time to convert
 * @returns formatted time
 */
export const formatTimeToStandardFormat = (time) => {
  const hours = Math.floor(time / hourToMillisecondFactor).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  time = time % hourToMillisecondFactor;
  const minutes = Math.floor(time / minuteToMillisecondFactor).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  time = time % minuteToMillisecondFactor;
  const seconds = (time / secondToMillisecondFactor).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    maximumFractionDigits: 3,
    useGrouping: false
  });
  return `${hours}:${minutes}:${seconds}`;
};

export const delay = (time) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
}