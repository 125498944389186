
const Login = () => import(/* webpackChunkName: "loginUser" */ 'Features/login/components/LoginUser')
const ProfileSelector = () => import(/* webpackChunkName: "profileSelector" */ 'Features/login/components/ProfileSelector')
const Authentication = () => import(/* webpackChunkName: "authentication" */ 'Features/login/Main')
const AccountSelector = () => import(/* webpackChunkName: "accountSelector" */ 'Features/login/components/AccountSelector')

const loginRoute =
{
  name: 'login',
  path: '/login',
  component: Login,
  meta: {
    requiresAuth: false,
    title: 'message.login'
  }
};

const profileSelectorRoute =
{
  name: 'profileSelection',
  path: '/profileSelection',
  component: ProfileSelector,
  meta: {
    requiresAuth: true,
    title: 'message.login'
  }
};

const accountSelectorRoute =
{
  name: 'accountSelection',
  path: '/profiles/:businessProfileId/accountSelection',
  component: AccountSelector,
  meta: {
    requiresAuth: true,
    title: 'message.login'
  }
}

export const authenticationRoute = {
  name: 'authentication',
  path: '/authentication',
  component: Authentication,
  children: [
    loginRoute,
    profileSelectorRoute,
    accountSelectorRoute
  ]
}

export const LoginRoute = loginRoute;
export const ProfileSelectorRoute = profileSelectorRoute;
export const AuthenticationRoute = authenticationRoute;
export const AccountSelectorRoute = accountSelectorRoute;