import AppConfig from 'Constants/AppConfig';

export const OnboardingRoute = Object.freeze(
  // Property keys are named based on their respective components
  // Route names and path can be changed as required.
  {
    Signup: {
      name: 'signup',
      path: '/signup'
    },
    StoreSelection: {
      name: 'storeFront',
      path: '/signup/storeFront'
    },
    SignupUser: {
      name: 'signupUser',
      path: '/signup/user'
    },
    LinkStore: {
      name: 'linkStore',
      path: '/signup/linkStore'
    },
    LinkInstructions: {
      name: 'linkInstructions',
      path: '/signup/linkInstructions'
    },
    OtherStore: {
      name: 'otherStore',
      path: '/signup/otherStore'
    },
    StoreDetails: {
      name: 'storeDetails',
      path: '/signup/storeDetails'
    },
    Assets: {
      name: 'onboarding-assets',
      path: '/signup/assets/:businessProfileId'
    },
    KYCVerification: {
      name: 'kycVerification',
      path: '/signup/verification/:businessProfileId?'
    },
    StoreConfirmation: {
      name: 'storeConfirmation',
      path: '/signup/confirm'
    },
    LocalBusiness: {
      name: 'localBusiness',
      path: '/localBusiness'
    },
    SetupCreativeBuilder: {
      name: 'setupCreativeBuilder',
      path: '/signup/setupCreativeBuilder'
    },
    SetupRichMediaAdBuilder: {
      name: 'setupRichMediaAdBuilder',
      path: '/signup/setupRichMediaAdBuilder'
    }
  });

export const OnboardingType = Object.freeze({
  Default: 0,
  Shopify: 1,
  Magento: 2,
  WooCommerce: 3,
  BigCommerce: 4,
  OtherStore: 5,
  EmbedProfile: 6,
  CreativeBuilder: 7,
  RichMediaAdBuilder: 8
});

export const OnboardingRouteConfig = Object.freeze({
  0: [
    OnboardingRoute.StoreSelection,
    OnboardingRoute.SignupUser,
    OnboardingRoute.LinkStore,
    OnboardingRoute.StoreDetails,
    OnboardingRoute.Assets,
    OnboardingRoute.KYCVerification
  ],
  1: [
    OnboardingRoute.StoreSelection,
    OnboardingRoute.SignupUser,
    OnboardingRoute.LinkStore,
    OnboardingRoute.StoreDetails,
    OnboardingRoute.Assets,
    OnboardingRoute.KYCVerification
  ],
  2: [
    OnboardingRoute.StoreSelection,
    OnboardingRoute.SignupUser,
    OnboardingRoute.LinkStore,
    OnboardingRoute.LinkInstructions,
    OnboardingRoute.StoreDetails,
    OnboardingRoute.Assets,
    OnboardingRoute.KYCVerification
  ],
  3: [
    OnboardingRoute.StoreSelection,
    OnboardingRoute.SignupUser,
    OnboardingRoute.LinkStore,
    OnboardingRoute.StoreDetails,
    OnboardingRoute.Assets,
    OnboardingRoute.KYCVerification
  ],
  4: [
    OnboardingRoute.StoreSelection,
    OnboardingRoute.SignupUser,
    OnboardingRoute.LinkStore,
    OnboardingRoute.StoreDetails,
    OnboardingRoute.Assets,
    OnboardingRoute.KYCVerification
  ],
  5: [
    OnboardingRoute.StoreSelection,
    OnboardingRoute.SignupUser,
    OnboardingRoute.OtherStore,
    OnboardingRoute.KYCVerification,
    OnboardingRoute.StoreConfirmation
  ],
  6: [OnboardingRoute.KYCVerification],
  7: [
    OnboardingRoute.SignupUser,
    OnboardingRoute.SetupCreativeBuilder
  ],
  8: [OnboardingRoute.SignupUser,
    OnboardingRoute.SetupRichMediaAdBuilder]
});

export const OnboardingImage = Object.freeze({
  StoreSelection: `${AppConfig.staticImagesBasePath}onboarding/choose-your-storefront.png`,
  SignupUser: `${AppConfig.staticImagesBasePath}onboarding/signup.png`,
  LinkStore: `${AppConfig.staticImagesBasePath}onboarding/link-store.png`,
  StoreDetails: `${AppConfig.staticImagesBasePath}onboarding/store-link-successful.png`,
  Assets: `${AppConfig.staticImagesBasePath}onboarding/link-assets.png`,
  KYCVerification: `${AppConfig.staticImagesBasePath}onboarding/profile-verification.png`,
  LinkInstructions: `${AppConfig.staticImagesBasePath}onboarding/store-link-instructions.png`,
  OtherStore: `${AppConfig.staticImagesBasePath}onboarding/other-store.png`,
  ThankYou: `${AppConfig.staticImagesBasePath}onboarding/thank-you.png`
});

export const categoryTypes = {
  ECommerce: 1,
  LocalBusiness: 2
}

export const productFeedPath = {
  path: '/AllFiles/Uploads/Roimatic/FacebookProductDataFeed'
}

export const assetTypes = {
  Facebook: 1,
  Google: 2
}

