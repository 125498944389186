const BusinessCategorySelector = () => import(/* webpackChunkName: "businessCategory" */ 'Features/businessCategorySelection/Main');

export default {
  name: 'businessCategory',
  path: 'businessCategory',
  component: BusinessCategorySelector,
  meta: {
    requiresAuth: true,
    title: 'message.selectBusinessCategory',
    breadcrumb: 'message.selectBusinessCategory'
  }
}
