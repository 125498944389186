import { shapes } from './shapes';
import { i18n } from '@/lang/'
export const imageNotAvailablePath = '/static/img/image-not-available.png';

export const defaultBgRemoveFilter = {
  enabled: false,
  bgRemovedUrl: '',
  steps: []
};

export const defaultBackgroundRemoveOptions = {
  model: 'u2net',
  alphaMatting: 'False',
  alphaMattingSettings: {
    foregroundThreshold: 240,
    backgroundThreshold: 10,
    erodeSize: 10
  },
  cropToVisiblePixels: 'False'
};

export const clippingDefaults = {
  enabled: false,
  clipPath: shapes[0].path,
  scaleX: 1,
  scaleY: 1,
  originX: 'center',
  originY: 'center',
  rotateAngle: 0,
  clipPositionOffsets: {
    hOffset: 0,
    vOffset: 0
  }
}

export const defaultInpaintForegroundFilter = {
  enabled: false,
  autoMask: true,
  maskUrl: '',
  expandMask: 30, // px
  fgRemovedUrl: ''
};

export const imageFitOptions = [
  { id: 0, name: i18n.tc('message.original') },
  { id: 1, name: i18n.tc('message.stretchToFit') },
  { id: 2, name: i18n.tc('message.fillToBounds') },
  { id: 3, name: i18n.tc('message.fitAndCrop') },
  { id: 4, name: i18n.tc('message.crop') },
  { id: 5, name: i18n.tc('message.clipToBounds') }
]

export const imageFits = {
  original: 0,
  stretchToFit: 1,
  fillToBounds: 2,
  fitAndCrop: 3,
  crop: 4,
  clipToBounds: 5
}
