const mediaDataUpload = () => import(/* webpackChunkName: "mediaDataUpload" */'./Main');

const mediaDataUploadRoute = {
  name: 'mediaDataUpload',
  path: 'mediaData',
  component: mediaDataUpload,
  meta: {
    requiresAuth: true,
    title: 'message.mediaDataUpload.mediaDataUpload',
    breadcrumb: 'message.mediaDataUpload.mediaDataUpload'
  }
};

export const MediaDataUploadRoute = mediaDataUploadRoute;