const RichMediaAdBuilder = () => import(/* webpackChunkName: "richMediaAdBuilder" */'Features/richMediaAdBuilder/Main');

export default {
  name: 'richMediaAdBuilder',
  path: 'richMediaAdBuilder',
  component: RichMediaAdBuilder,
  meta: {
    requiresAuth: true,
    title: 'message.richMediaAdBuilder.adBuilder',
    breadcrumb: 'message.richMediaAdBuilder.adBuilder',
    customLayout: true
  }
}
