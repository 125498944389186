export const icons = [
  'email',
  'lock',
  'lock_open',
  'visibility',
  'visibility_off',
  'perm_identity',
  'calendar_today',
  'money',
  'event',
  'people',
  'school',
  'photo_library',
  'calendar_today',
  'filter_list',
  'public',
  'room',
  'schedule',
  'store',
  'phone',
  'language',
  'place',
  'image',
  'add_circle',
  'edit',
  'chevron_right',
  'chevron_left',
  'maximize',
  'panorama_fish_eye',
  'check_circle',
  'add',
  'supervised_user_circle',
  'shopping_cart',
  'map_marker',
  'info',
  'rotate_right',
  'portrait',
  'replay',
  'cloud_upload',
  'aspect_ratio',
  'arrow_drop_up',
  'arrow_left',
  'arrow_right',
  'arrow_drop_down',
  'rotate_right',
  'expand_less',
  'check',
  'close',
  'delete_forever',
  'save',
  'collections_bookmark',
  'insert_photo',
  'delete',
  'add_photo_alternate',
  'format_bold',
  'format_italic',
  'format_underlined',
  'format_align_left',
  'format_align_center',
  'format_align_right',
  'account_balance_wallet',
  'get_app',
  'trending_up',
  'account_circle',
  'business',
  'cancel',
  'flag',
  'local_atm',
  'pages',
  'search',
  'stars',
  'payment',
  'vpn_key',
  'settings',
  'description',
  'contact_support',
  'local_offer',
  'content_save_edit'
]
