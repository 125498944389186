export default {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'en-IN': {
    currency: {
      style: 'currency', currency: 'INR'
    }
  },
  'ja-JP': {
    currency: {
      style: 'currency', currency: 'JPY', currencyDisplay: 'symbol'
    }
  }
}