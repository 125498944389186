const ForgotPassword = () => import(/* webpackChunkName: "forgotPassword" */ 'Features/forgotPassword/ForgotPassword');
const ResetPassword = () => import(/* webpackChunkName: "resetPassword" */ 'Features/forgotPassword/ResetPassword');

const forgotPassword = {
  name: 'forgotPassword',
  path: '/forgotPassword',
  component: ForgotPassword,
  meta: {
    requiresAuth: false
  }
};

const resetPassword = {
  name: 'resetPassword',
  path: '/resetPassword',
  component: ResetPassword,
  meta: {
    requiresAuth: false
  }
}

export const ForgotPasswordRoutes = [forgotPassword, resetPassword]
export const ForgotPasswordRouteObjects = Object.freeze({ forgotPassword, resetPassword })