import { black } from './colors';

export const defaultShadow = {
  enabled: false,
  offsetX: 0,
  offsetY: 5,
  blur: 5,
  color: black
}

export const globalBlendModes = {
  SOURCE_OVER: {
    value: 'source-over',
    label: 'Source Over'
  },
  SOURCE_IN: {
    value: 'source-in',
    label: 'Source In'
  },
  SOURCE_OUT: {
    value: 'source-out',
    label: 'Source Out'
  },
  SOURCE_ATOP: {
    value: 'source-atop',
    label: 'Source Atop'
  },
  DESTINATION_OVER: {
    value: 'destination-over',
    label: 'Destination Over'
  },
  DESTINATION_IN: {
    value: 'destination-in',
    label: 'Destination In'
  },
  DESTINATION_OUT: {
    value: 'destination-out',
    label: 'Destination Out'
  },
  DESTINATION_ATOP: {
    value: 'destination-atop',
    label: 'Destination Atop'
  },
  LIGHTER: {
    value: 'lighter',
    label: 'Lighter'
  },
  COPY: {
    value: 'copy',
    label: 'Copy'
  },
  XOR: {
    value: 'xor',
    label: 'Xor'
  },
  MULTIPLY: {
    value: 'multiply',
    label: 'Multiply'
  },
  SCREEN: {
    value: 'screen',
    label: 'Screen'
  },
  OVERLAY: {
    value: 'overlay',
    label: 'Overlay'
  },
  DARKEN: {
    value: 'darken',
    label: 'Darken'
  },
  LIGHTEN: {
    value: 'lighten',
    label: 'Lighten'
  },
  COLOR_DODGE: {
    value: 'color-dodge',
    label: 'Color Dodge'
  },
  COLOR_BURN: {
    value: 'color-burn',
    label: 'Color Burn'
  },
  HARD_LIGHT: {
    value: 'hard-light',
    label: 'Hard Light'
  },
  SOFT_LIGHT: {
    value: 'soft-light',
    label: 'Soft Light'
  },
  DIFFERENCE: {
    value: 'difference',
    label: 'Difference'
  },
  EXCLUSION: {
    value: 'exclusion',
    label: 'Exclusion'
  },
  HUE: {
    value: 'hue',
    label: 'Hue'
  },
  SATURATION: {
    value: 'saturation',
    label: 'Saturation'
  },
  COLOR: {
    value: 'color',
    label: 'Color'
  },
  LUMINOSITY: {
    value: 'luminosity',
    label: 'Luminosity'
  }
}

export const defaultGlobalBlend = {
  enabled: false,
  operation: globalBlendModes.SOURCE_OVER.value
}