// helper article components
const HelpArticles = () => import(/* webpackChunkName: "dashboard" */ 'Features/helpArticles/Main');

const helpArticlesRoute =  {
  name: 'helpArticles',
  path: 'helpArticles',
  component: HelpArticles,
  meta: {
    requiresAuth: true,
    title: 'message.helpArticles',
    breadcrumb: 'message.helpArticles'
  }
}

const portalHelpArticlesRoute =  {
  name: 'portal-helpArticles',
  path: '/helpArticles',
  component: HelpArticles,
  meta: {
    requiresAuth: true,
    title: 'message.helpArticles',
    breadcrumb: 'message.helpArticles'
  }
}

export const HelpArticlesRoute = helpArticlesRoute;
export const PortalHelpArticlesRoute = portalHelpArticlesRoute;