<template>
  <div class="app-footer" :class="[customClasses]">
    <slot />
  </div>
</template>
<script>
export default {
  props: ['customClasses']
}
</script>
