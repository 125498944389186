const Payment = () => import(/* webpackChunkName: "wallet" */ 'Features/wallet/Main');
const DetailedInvoice = () => import(/* webpackChunkName: "invoice" */ 'Features/wallet/components/DetailedInvoice');

export default [
  {
    name: 'wallet',
    path: 'wallet',
    component: Payment,
    meta: {
      requiresAuth: true,
      title: 'message.wallet',
      breadcrumb: 'message.wallet'
    }
  },
  {
    name: 'invoice',
    path: 'invoice/:id',
    component: DetailedInvoice,
    meta: {
      requiresAuth: true,
      title: 'message.invoice',
      breadcrumb: 'message.invoice'
    }
  }
]
