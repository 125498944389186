<template>
  <v-app
    id="inspire"
    :class="[{
      'box-layout': boxLayout,
      'collapse-sidebar': collapseSidebar,
      'rtl-layout': rtlLayout
    }]"
  >
    <router-view :auth="auth" :authenticated="authenticated" />
    <notifications group="loggedIn" position="top right" animation-type="velocity" />
    <notifications group="onboarding" position="top right" animation-type="velocity" />
    <notifications
      group="payment"
      position="top right"
      animation-type="velocity"
      :max="1"
    />
    <notifications group="assets" position="top right" animation-type="velocity" />
    <notifications group="creativeEdit" position="top right" animation-type="velocity" />
    <notifications
      group="creativeBuilder"
      position="top right"
      animation-type="velocity"
      :close-on-click="true"
      class="custom-cb-notification"
    />
    <notifications group="richMediaAdBuilder" position="top right" animation-type="velocity" />
    <notifications group="richMediaAdPreview" position="top right" animation-type="velocity" />
    <notifications group="EmailVerification" position="top right" animation-type="velocity" />
    <notifications group="embed" position="top right" animation-type="velocity" />
    <notifications group="dashboard" position="top right" animation-type="velocity" />
    <notifications group="brandPortal" position="top right" animation-type="velocity" />
    <notifications group="reporting" position="top right" animation-type="velocity" />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import { AuthService } from './appServices/authService';

const auth = new AuthService();

const { authenticated, authNotifier } = auth;

export default {
  data() {
    authNotifier.on('authChange', authState => {
      this.authenticated = authState.authenticated;
    });
    return {
      auth,
      authenticated,
      animation: {
        enter: {
          opacity: [1, 0],
          translateX: [0, -300],
          scale: [1, 0.2]
        },
        leave: {
          opacity: 0,
          height: 0
        }
      }
    };
  },
  mounted() {
    if (
      this.selectedLocale.locale === 'he' ||
      this.selectedLocale.locale === 'ar'
    ) {
      this.$store.dispatch('rtlLayout');
    }
  },
  computed: {
    ...mapGetters([
      'darkMode',
      'collapseSidebar',
      'boxLayout',
      'rtlLayout',
      'selectedLocale'
    ])
  }
};
</script>
<style scoped>
.custom-cb-notification {
  top: 7vh !important;
}
</style>