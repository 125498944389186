<template>
  <div class="loader-overlay" v-if="status">
    <v-progress-circular indeterminate color="primary" />
  </div>
</template>
<script>
import { VProgressCircular } from 'vuetify/lib'
export default {
  props: ['status'],
  components: {
    VProgressCircular
  }
};
</script>
