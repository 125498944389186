import en from './en/index.json';
import fr from './fr/index.json';
import he from './he/index.json';
import ru from './ru/index.json';
import ar from './ar/index.json';
import cn from './cn/index.json';
import ge from './ge/index.json';
import sp from './sp/index.json';
import ja from './ja/index.json';
import ko from './ko/index.json';
import it from './it/index.json';
import hu from './hu/index.json';
import pt_PT from './pt_PT/index.json';
import hi from './hi/index.json';

export default {
  en: en,
  fr: fr,
  he: he,
  ru: ru,
  ar: ar,
  cn: cn,
  ge: ge,
  sp: sp,
  ja: ja,
  ko: ko,
  it: it,
  hu: hu,
  pt_PT: pt_PT,
  hi: hi
}