import EventEmitter from 'eventemitter3'
import api from 'Api';
import { LoginProviderTypes } from '../constants/loginProviderTypes';
import { LoginError } from '../errors/loginError';

export class AuthService {
  constructor() {
    this.login = this.login.bind(this)
    this.setSession = this.setSession.bind(this)
    this.logout = this.logout.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.signUpUser = this.signUpUser.bind(this);
    this.getPortalData = this.getPortalData.bind(this);
    this.authenticated = this.isAuthenticated()
    this.authNotifier = new EventEmitter();
  }

  async signUpUser(user) {
    var userData = {
      firstName: user.firstName,
      lastName: user.lastName,
      emailAddress: user.emailAddress,
      loginPassword: user.password,
      isTosAccepted: true,
      loginProviderType: user.loginProviderType,
      portalDomain: user.portalDomain,
      loginName: user.loginName,
      isEmailVerified: !!user.isEmailVerified
    };
    var response = await api.post('/roimatic/users', userData);
    return response.data.id;
  }

  async login(loginDetails, portal) {
    if (!loginDetails.hasOwnProperty('loginProviderType')) {
      loginDetails.loginProviderType = LoginProviderTypes.DeltaX;
    }

    var login = {
      userName: loginDetails.emailAddress,
      password: loginDetails.password,
      portal: portal,
      loginProviderType: loginDetails.loginProviderType
    }
    var response = await api.post('/authentication/login', login);
    localStorage.setItem('accessToken', response.data.accessToken);
    return response.data;
  }

  async loginWithEmbed(embedToken) {
    let response = await api.post('/authentication/loginWithEmbedToken', {
      embedToken,
      portal: window.location.host
    });
    return response.data;
  }

  async getPortalData(portalDomain) {
    let response = await api.get('/portals', {
      headers: { 'X-Bypass-Login': true },
      params: {
        domain: portalDomain
      }
    });
    let responseData = response.data.data;
    return responseData && Array.isArray(responseData) && responseData.length > 0 ? responseData[0] : null;
  }

  async loginWithProvider(loginWithProviderDetails = {}) {
    if (!loginWithProviderDetails.hasOwnProperty('loginProviderType')) {
      loginWithProviderDetails.loginProviderType = LoginProviderTypes.Google;
    }

    var loginWithProvider = {
      portal: loginWithProviderDetails?.portal,
      loginProviderType: loginWithProviderDetails?.loginProviderType,
      authorizationCode: loginWithProviderDetails?.authorizationCode
    }
    var response = await api.post('/authentication/token', loginWithProvider);

    if (!response?.data?.accessToken) {
      throw new LoginError();
    }

    localStorage.setItem('accessToken', response.data.accessToken);
    return response.data;
  }

  handleAuthentication() {
  }

  setSession() {
  }

  logout() {
  }

  isAuthenticated() {
  }
}
