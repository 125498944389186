const Embed = () => import(/* webpackChunkName: "embed" */ 'Features/embed/Embed');
const EmbedLogin = () => import(/* webpackChunkName: "embedLogin" */'Features/embed/EmbedLogin');

const EmbedRoute =  {
  name: 'embed',
  path: '/embed',
  component: Embed,
  meta: {
    title: 'embed',
    breadcrumb: 'embed'
  }
}

const EmbedLoginRoute = {
  name: 'embed-login',
  path: '/embed/login',
  component: EmbedLogin,
  meta: {
    requiresAuth: false
  }
}

export const EmbedRouteObjects = { EmbedLoginRoute, EmbedRoute }
export const EmbedRoutes = [EmbedLoginRoute, EmbedRoute];
