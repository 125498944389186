const StoreSetup = () => import(/* webpackChunkName: "storeSetup" */ 'Features/storeSetup/Main');

export default {
  name: 'storeSetup',
  path: 'storeSetup',
  component: StoreSetup,
  meta: {
    requiresAuth: true,
    title: 'message.storeProfile',
    breadcrumb: 'message.storeProfile'
  }
}
