const richMediaAdShowcase = () => import('Features/richMediaAdShowcase/Main');

export default {
  name: 'richMediaAdShowcase',
  path: 'richMediaAdShowcase',
  component: richMediaAdShowcase,
  meta: {
    requiresAuth: false,
    title: 'RichMediaAdShowcase',
    breadcrumb: 'RichMediaAdShowcase'
  }
}