const adhocQueryReports = () => import(/* webpackChunkName: "adhocQueryReports" */'./Main');

const adhocQueryReportsRoute = {
  name: 'queryReports',
  path: 'queryReports',
  component: adhocQueryReports,
  meta: {
    requiresAuth: true,
    title: 'message.adhocQueryReports.queryReports',
    breadcrumb: 'message.adhocQueryReports.queryReports'
  }
};

export const AdhocQueryReportsRoute = adhocQueryReportsRoute;