const Dashboard = () => import(/* webpackChunkName: "dashboard" */ 'Features/dashboard/Main');
const mediaDataUpload = () => import(/* webpackChunkName: "mediaDataUpload" */'Features/mediaDataUpload/Main');
const trackingLink = () => import(/* webpackChunkName: "trackingLink" */'Features/trackingLink/Main');
const postbackUrls = () => import(/* webpackChunkName: "postbackUrl" */'Features/postbackUrl/Main');
const adhocQueryReports = () => import(/* webpackChunkName: "adhocQueryReports" */'Features/adhocQueryReports/Main');
const deals = () => import(/* webpackChunkName: "deals" */'Features/deals/Main');

const dashboardAffiliateRoute =  {
  name: 'affiliate-dashboard',
  path: 'dashboard/:accountId',
  component: Dashboard,
  meta: {
    requiresAuth: true,
    title: 'message.dashboard',
    breadcrumb: 'message.dashboard',
    showDateRangeFilter: false
  }
}

const mediaDataUploadAffiliateRoute = {
  name: 'affiliate-mediaDataUpload',
  path: 'mediaData/:accountId',
  component: mediaDataUpload,
  meta: {
    requiresAuth: true,
    title: 'message.mediaDataUpload.mediaDataUpload',
    breadcrumb: 'message.mediaDataUpload.mediaDataUpload'
  }
};

const trackingLinkAffiliateRoute = {
  name: 'affiliate-trackingLink',
  path: 'trackingLink/:accountId',
  component: trackingLink,
  meta: {
    requiresAuth: true,
    title: 'message.trackingLink.trackingLink',
    breadcrumb: 'message.trackingLink.trackingLink'
  }
};

const postbackUrlAffiliateRoute = {
  name: 'affiliate-postbackUrl',
  path: 'pixel/:accountId',
  component: postbackUrls,
  meta: {
    requiresAuth: true,
    title: 'message.postbackUrl.postbackUrls',
    breadcrumb: 'message.postbackUrl.postbackUrls'
  }
};

const adhocQueryReportsAffiliateRoute = {
  name: 'affiliate-queryReports',
  path: 'queryReports/:accountId',
  component: adhocQueryReports,
  meta: {
    requiresAuth: true,
    title: 'message.adhocQueryReports.queryReports',
    breadcrumb: 'message.adhocQueryReports.queryReports'
  }
};

const dealsAffiliateRoute = {
  name: 'affiliate-deals',
  path: 'deals/:accountId',
  component: deals,
  meta: {
    requiresAuth: true,
    title: 'message.deal.dealsDashboard',
    breadcrumb: 'message.deal.dealsDashboard'
  }
};

export const AdhocQueryReportsAffiliateRoute = adhocQueryReportsAffiliateRoute;
export const MediaDataUploadAffiliateRoute = mediaDataUploadAffiliateRoute;
export const PostbackUrlAffiliateRoute = postbackUrlAffiliateRoute;
export const TrackingLinkAffiliateRoute = trackingLinkAffiliateRoute;
export const DashboardAffiliateRoute = dashboardAffiliateRoute;
export const DealsAffiliateRoute = dealsAffiliateRoute;