import { store } from 'Store/store'

import { AppService } from 'AppServices/appService';

const { getPortalBusinessProfiles } = new AppService();

export class BusinessProfilesHelper {
  async getBusinessProfiles(isRoimatic = null){
    let user = store.state.auth.user;
    let portal = store.state.auth.portal;
    let businessProfilesFetchRequest = getPortalBusinessProfiles(user.id, portal.id, isRoimatic);
    return await businessProfilesFetchRequest;
  }
}