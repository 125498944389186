const trackingLink = () => import(/* webpackChunkName: "trackinglink" */'./Main');

const trackinglinkRoute = {
  name: 'trackingLink',
  path: 'trackinglink',
  component: trackingLink,
  meta: {
    requiresAuth: true,
    title: 'message.trackingLink.trackingLink',
    breadcrumb: 'message.trackingLink.trackingLink'
  }
};

export const TrackingLinkRoute = trackinglinkRoute;