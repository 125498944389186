// Executes different flows depending on whether the env is node or browser.

import { fabric } from 'fabric';
import { layers as layerTypes } from '../constants/layers';
import { defaultAudioBitrate, lastResetWait, positions } from '../constants/animation';
import { generateHtmlMediaElements } from '../helpers/timelineHelper';
import { loadMediaElement, createVideoFabricObject } from '../helpers/videoHelper';
import { setFfmpegLogging, getFfmpegLogs, clearFfmpegLogs } from '../helpers/ffmpegHelper';
import { getSpeechData, updateAudioLayer } from '../helpers/audioHelper';
import { nodeNames } from '../constants/commonData';
import { getComputedLayer } from './commonHelper';
import { formatTimeToMilliseconds } from './Utils';
import { updateSources } from './tweenHelper';

const isNode = fabric.isLikelyNode;

export const apiPayload = {
  token: '',
  baseUrl: '',
  bpId: null,
  get accessToken() {
    if (isNode) {
      return this.token;
    }
    else {
      return localStorage.getItem('accessToken');
    }
  },
  set accessToken(value) {
    this.token = value
  },
  get baseURL() {
    if (isNode) {
      return this.baseUrl;
    }
    else {
      return process.env.VUE_APP_API_URL;
    }
  },
  set baseURL(value) {
    this.baseUrl = value
  },
  get businessProfileId() {
    return this.bpId;
  },
  set businessProfileId(value) {
    this.bpId = value
  }
}

export const getAdHtmlCanvas = (ad) => {
  if (isNode) {
    return ad.canvas;
  }
  else {
    return document.getElementById(ad.adId);
  }
}

export const resetMedia = async (ad, adLayer, videoArrays, isForDownload, layers) => {
  if (isNode) {
    if (adLayer.type != layerTypes.video || !videoArrays || videoArrays.length == 0) {
      return;
    }
    var videoArray = videoArrays.find(v => v.id == adLayer.parentId);
    if (!videoArray.fileUrls || videoArray.fileUrls.length == 0) {
      return;
    }
    // On resetting, the first frame of video should be updated.
    await new Promise((resolve, reject) => {
      adLayer.fabricObject.setSrc(videoArray.fileUrls[0], () => {
        resolve();
      });
    });
  } else if (isForDownload) {
    if (adLayer.type != layerTypes.video) {
      return;
    }

    const mediaElement = adLayer.fabricObject && adLayer.fabricObject.getElement();
    // On downloading, fabric image object gets changed to image element.
    // Changing the fabric object to point to video element again.
    if (mediaElement.nodeName != nodeNames.video) {
      const videoElementBackup = adLayer.videoElementBackup;
      const videoDataLayer = getComputedLayer(layers, ad, adLayer.parentId);
      const videoFabricObject = await createVideoFabricObject(ad, videoElementBackup, videoDataLayer);
      adLayer.fabricObject = videoFabricObject;
      const oldFabricObjectIndex = ad.canvas._objects.findIndex(o => o.layerId && o.layerId == adLayer.parentId);
      ad.canvas._objects.splice(oldFabricObjectIndex, 1);
      ad.canvas.add(videoFabricObject);
      ad.canvas.moveTo(videoFabricObject, oldFabricObjectIndex);
      ad.canvas.renderAll();
      delete adLayer.videoElementBackup;
    }
  } else {
    const mediaElement = adLayer.fabricObject.getElement();
    if(mediaElement.src) {
      mediaElement.currentTime = 0;
      await new Promise((resolve, reject)=>{
        mediaElement.addEventListener('seeked', (event) => {
          resolve();
        });
      });
    }
  }
}

export const renderAnimations = async (
  rafRequests,
  startTime,
  timelineTime,
  tweenGroup,
  resetGroup,
  ad,
  downloadConfig,
  allLoopsDuration,
  adHtmlCanvas,
  videoArrays,
  builder,
  renderOptions,
  animations,
  state
) => {
  if (isNode) {
    // In case of download, the frames are looped manually and current time is calculated from fps.
    var framesPerLoop = Math.floor(ad.maxAdEndTime * renderOptions.framerate);
    var frameDuration = 1000 / renderOptions.framerate;
    for (let currentLoop = 0; currentLoop < downloadConfig.loopCount; currentLoop++) {
      var completedFrames = currentLoop * framesPerLoop;
      for (let currentFrame = 0; currentFrame <= framesPerLoop; currentFrame++) {
        if (currentFrame == 0 && currentLoop != 0) {
          continue;
        }
        let currentTime = (currentLoop * ad.maxAdEndTime * 1000) + (frameDuration * currentFrame);
        let frame = completedFrames + currentFrame;
        await render(tweenGroup, resetGroup, ad, downloadConfig, allLoopsDuration, currentTime, adHtmlCanvas, animations, state, videoArrays, frame, builder, renderOptions.framerate);
      }
    }
  }
  else {
    // Use window.requestAnimationFrame instead of fabric.util.requestAnimFrame
    // CCapture overrides window.requestAnimationFrame to ensure constant FPS
    // https://github.com/spite/ccapture.js/issues/14#issuecomment-860157669
    rafRequests.push(window.requestAnimationFrame(async function() {
      await renderAnimations(
        rafRequests,
        startTime,
        timelineTime,
        tweenGroup,
        resetGroup,
        ad,
        downloadConfig,
        allLoopsDuration,
        adHtmlCanvas,
        videoArrays,
        builder,
        renderOptions,
        animations,
        state
      );
    }));
    // rendering stuff ... Read more about this render loop here - https://github.com/spite/ccapture.js#using-the-code
    let ct = Date.now();
    let currentTime = (ct - startTime) + timelineTime;
    if (downloadConfig.loopCount && !downloadConfig.isMediaRecorder) {
      await render(tweenGroup, resetGroup, ad, downloadConfig, allLoopsDuration, currentTime, adHtmlCanvas, animations, state, videoArrays, null, builder, renderOptions.framerate);
    } else {
      await render(tweenGroup, resetGroup, ad, downloadConfig, allLoopsDuration, currentTime, adHtmlCanvas, animations, state);
    }
  }
}

const render = async (
  tweenGroup,
  resetGroup,
  ad,
  downloadConfig,
  allLoopsDuration,
  currentTime,
  adHtmlCanvas,
  animations,
  state,
  videoArrays,
  frame,
  builder,
  framerate
) => {
  await updateSources(state, ad, currentTime);
  tweenGroup.group.update(currentTime);
  // In case of ad download, video will be split into frames instead of generating html element.
  // While rendering the frames, the corresponding video frame according to current time will be captured and
  // fabric.Image will be updated.
  if (isNode || (downloadConfig.loopCount && !downloadConfig.isMediaRecorder)) {
    var videoLayers = ad.layers.filter(l => l.type == layerTypes.video);
    await videoLayers.forEachAsync(async layer => {
      let newCurrentTime = (currentTime > (ad.maxAdEndTime * 1000)) ? (currentTime % (ad.maxAdEndTime * 1000)) : currentTime;
      const videoAnimations = animations.find(a => a.layerId === layer.parentId);
      const layerStartTime = videoAnimations[positions.start].startTime * 1000;
      const layerEndTime = videoAnimations[positions.end].endTime * 1000;
      const computedLayer = getComputedLayer(state.layers, ad, layer.parentId);
      const videoStartTime = computedLayer.data.props.startTime * 1000;
      const videoEndTime = computedLayer.data.props.endTime * 1000;
      computedLayer.data.props.src = replaceWithCDN(computedLayer.data.props.src);
      let duration = formatTimeToMilliseconds(computedLayer.data.props.duration);
      // video has been trimmed
      if ((videoEndTime - videoStartTime) < duration) {
        duration = videoEndTime - videoStartTime
      }
      // Fabric video object should be loaded with empty image on 3 conditions:
      // 1. When layer start time is not reached, video should not start
      // 2. When current time goes out of bound of layer end time
      // 3. When the layer animation duration is long enough and actual video finishes before it
      //    Additional condition for (3) is loop should not be enabled
      if (newCurrentTime < layerStartTime
        || newCurrentTime > layerEndTime
        || (newCurrentTime > (layerStartTime + duration) && !computedLayer.data.props.loop)) {
        await new Promise((resolve, reject) => {
          layer.fabricObject.setSrc('', () => {
            resolve();
          }, { crossOrigin: 'anonymous' });
        });
      } else  {
        const videoArray = videoArrays.find(v => v.id === layer.parentId && v.src === computedLayer.data.props.src);
        // if loop is enabled and currentTime goes out of bound of video duration,
        // we need to make sure currentTime is always under video start and end time,
        // so that correct frame is chosen
        if (computedLayer.data.props.loop && newCurrentTime > (layerStartTime + duration)) {
          newCurrentTime = ((newCurrentTime - layerStartTime) % duration) + videoStartTime;
        } else {
          newCurrentTime = newCurrentTime - layerStartTime + videoStartTime;
        }
        if (layer.fabricObject && videoArray && videoArray.fileUrls && videoArray.fileUrls.length > 0) {
          // Calculating the frame of video to be displayed based on current time.
          let currentFrame = Math.round((newCurrentTime * framerate) / 1000);
          let renderFile = videoArray.fileUrls[currentFrame];
          if (!renderFile) {
            renderFile = videoArray.fileUrls[videoArray.fileUrls.length - 1];
          }
          await new Promise((resolve, reject) => {
            layer.fabricObject.setSrc(renderFile, () => {
              resolve();
            });
          });
        }
      }
    });
  }
  resetGroup.update(currentTime);
  ad.canvas.renderAll();
  if (downloadConfig.loopCount && !downloadConfig.isMediaRecorder) {
    if (currentTime < allLoopsDuration)
      builder.capture({
        canvas: adHtmlCanvas,
        frame
      });
    else
      resetGroup.update(allLoopsDuration + lastResetWait);
  }
}

// No need to generate media elements in case of node
export const generateMediaElements = async (state, layer, ad, timeline, animation, downloadLoopCount, ads, tweenLayer, tweenGroup, timelineTime, loopCount, adVideos, computedLayer) => {
  let isAudioPresent = false;
  if (!isNode && !downloadLoopCount) {
    isAudioPresent = await generateHtmlMediaElements(
      state,
      layer,
      ad,
      timeline,
      animation,
      ads,
      tweenLayer,
      tweenGroup,
      timelineTime,
      loopCount,
      adVideos,
      computedLayer
    );
  }
  return isAudioPresent;
}

export const getAudioContext = () => {
  if (isNode) {
    var dummyAudioContext = function () {
      function createMediaStreamDestination() {
        return {
          aStream: null
        }
      }
      return {
        createMediaStreamDestination
      }
    }
    return new dummyAudioContext();
  }
  else {
    return new AudioContext();
  }
}

// Need to create audio/video html elements only in browser. There is no use for html media elements in node, hence return empty.
export const createVideoElementForCanvas = async (ad, props) => {
  if (isNode) {
    return '';
  }
  else {
    let videoUrl = props.renderSrc || props.src;
    videoUrl = replaceWithCDN(videoUrl);
    var videoElement = document.createElement('video');
    videoElement.id = 'video_' + ad.adId;
    videoElement.src = videoUrl;
    await initializeMediaElement(videoElement, ad);
    videoElement.width = videoElement.videoWidth;
    videoElement.height = videoElement.videoHeight;
    return videoElement;
  }
}

export const updateVideoElement = async (fabricObject, newSrc) => {
  if (!isNode) {
    let videoElement = fabricObject.getElement();
    videoElement.src = replaceWithCDN(newSrc);
    await loadMediaElement(videoElement);
    videoElement.width = videoElement.videoWidth;
    videoElement.height = videoElement.videoHeight;
    return videoElement;
  }
}

export const createAudioElementForCanvas = async (ad, props) => {
  if (isNode) {
    return '';
  }
  else {
    var audioElement = document.createElement('audio');
    audioElement.id = 'audio_' + ad.adId;
    if (props.useTts) {
      const ttsData = await getSpeechData(props);
      if (ttsData) {
        audioElement.src = ttsData;
      }
    } else {
      audioElement.src = props.src;
    }
    if (audioElement.src) {
      await initializeMediaElement(audioElement, ad);
    }
    return audioElement;
  }
}

export const createAudioFabricObject = async (ad, layer) => {
  layer.data.props.src = replaceWithCDN(layer.data.props.src);
  const audioElement = await createAudioElementForCanvas(ad, layer.data.props);
  if (isNode) {
    return new fabric.Image();
  }
  else {
    var audioObject = new fabric.Image(audioElement, {
      ...layer.data.styles,
      objectCaching: false
    }, {
      crossOrigin: 'anonymous'
    });

    return audioObject;
  }
}

export const updateAudioElement = async (fabricObject, data, state, layerToUpdate) => {
  if (isNode) {
    await getSpeechData(data.props);
    var existingLayer = state.layers.find(layer => layer.id == layerToUpdate.parentId);
    updateAudioLayer(existingLayer, data);
  }
  else {
    const audioElement = fabricObject.getElement();
    if (data.props.useTts) {
      const ttsData = await getSpeechData(data.props);
      if (ttsData) {
        audioElement.src = ttsData
      } else {
        // When an empty string is assigned to the src attribute of an HTML <audio> element,
        // the browser may interpret this as a relative URL pointing to the current page or the root of the current domain
        // We need to remove src attribute to load an empty media element
        audioElement.removeAttribute('src');
      }
    } else if (data.props.src) {
      data.props.src = replaceWithCDN(data.props.src);
      audioElement.src = data.props.src;
    } else {
      audioElement.removeAttribute('src');
    }
    if (audioElement.src) {
      await loadMediaElement(audioElement);
    }
  }
}

const initializeMediaElement = async (mediaElement, ad) => {
  mediaElement.controls = true;
  mediaElement.style.display = 'none';
  mediaElement.crossOrigin = 'anonymous';
  mediaElement.preload = 'auto';
  mediaElement.addEventListener('error', () => {
    if (!ad || !ad.canvas) {
      return;
    }
    const loaderTextFabricObject = ad.canvas._objects.find(o => o.isLoaderText);
    if (loaderTextFabricObject) {
      ad.canvas.remove(loaderTextFabricObject);
    }
  });
  await loadMediaElement(mediaElement);
}

export const fetchAndWriteFile = async (ffmpeg, src) => {
  if (!isNode) {
    const { fetchFile } = FFmpeg;
    ffmpeg.FS('writeFile', 'video.mp4', await fetchFile(src));
  }
}

// Node uses fluent ffmpeg while browser uses ffmpeg wasm.
// Both way of splitting a video to images are different.
export const splitFileToImages = async (ffmpeg, layer, framerate, payload, videoMetaData) => {
  // This is a hacky way to find the video format. We need to find a better way.
  const src = layer.data.props.src
  const videoFormat = src.split('.').pop();
  let webmCodec = 'libvpx-vp9';
  if (videoMetaData.codec === 'vp8') {
    webmCodec = 'libvpx';
  }
  if (isNode) {
    const { fs, dataFolderPath, refId } = payload;
    fs.mkdirSync(`${dataFolderPath}\\${refId}\\temp\\${layer.id}`);
    var outputOptions = [
      '-vf',
      `fps=${framerate}`
    ];
    var inputOptions = [];
    if(videoFormat === 'webm') {
      // To preserve transparency, we need to use libvpx-vp9 codec.
      inputOptions.unshift('-vcodec', webmCodec);
    }
    await new Promise((resolve, reject) => {
      ffmpeg()
        .input(src)
        .inputOptions(inputOptions)
        .outputOptions(outputOptions)
        .output(`${payload.dataFolderPath}\\${payload.refId}\\temp\\${layer.id}\\out-%04d.png`)
        .on('end', () => {
          resolve();
        })
        .on('error', (err) => {
          // eslint-disable-next-line no-console
          console.log('ffmpeg failed - ' + err);
          reject();
        })
        .run();
    });
  }
  else {
    if(videoFormat === 'webm') {
      await ffmpeg.run('-vcodec', webmCodec, '-i', 'video.mp4', '-vf', `fps=${framerate}`, 'out-%04d.png');
    }
    else {
      await ffmpeg.run('-i', 'video.mp4', '-vf', `fps=${framerate}`, 'out-%04d.png');
    }
  }
}

export const deleteFile = (ffmpeg, fileName) => {
  if (!isNode) {
    ffmpeg.FS('unlink', fileName);
  }
}

const readFile = async (ffmpeg, fileName) => {
  const imageData = ffmpeg.FS('readFile', fileName);
  return imageData;
}

// For node, after splitting video to images, those images will be taken directly while loading to fabric object
// so adding the file paths to all images
// For browser, we are converting the image data to blob urls and storing those.
// Both types of url will be passed to setSrc
export const prepareVideoFileUrls = async (ffmpeg, fileUrls, fileName, payload, layerId) => {
  if (isNode) {
    fileUrls.push(`file://${payload.dataFolderPath}/${payload.refId}/temp/${layerId}/${fileName}`);
  } else {
    const imageData = await readFile(ffmpeg, fileName);
    const uint8Array = new Uint8Array(imageData);
    const blob = new Blob([uint8Array], { type: 'image/png' });
    const fileUrl = URL.createObjectURL(blob);
    fileUrls.push(fileUrl);
  }
};

export const getVideoData = async (ffmpeg, src) => {
  const metaData = {
    isAudioPresent: false,
    audioBitrate: defaultAudioBitrate,
    codec: ''
  };
  if (isNode) {
    let probeData = {};
    await new Promise((resolve, reject) => {
      ffmpeg.ffprobe(src, (err, metadata) => {
        if (err) {
          // eslint-disable-next-line no-console
          console.log(err);
          reject();
        }
        probeData = metadata;
        resolve();
      });
    });
    const audioStream = probeData.streams.find(stream => stream.codec_type.toLowerCase() === 'audio');
    if (audioStream) {
      metaData.isAudioPresent = true;
    }
    const videoStream = probeData.streams.find(s => s.codec_type.toLowerCase() === 'video');
    metaData.codec = videoStream.codec_name.toLowerCase();
  } else {
    setFfmpegLogging(true);
    await ffmpeg.run('-i', 'video.mp4');
    setFfmpegLogging(false);
    const logs = getFfmpegLogs();
    const data = getDataFromLogs(logs);
    Object.assign(metaData, data);
    clearFfmpegLogs();
  }
  return metaData;
};

// ffmpeg command is logged. If audio is present, it will be in the metadata of Streams.
const getDataFromLogs = (logs) => {
  let isAudioPresent = false;
  let bitrate = defaultAudioBitrate;
  let codec = '';
  for (let i = 0; i < logs.length; i++) {
    const ffmpegLog = logs[i].trim();
    const log = ffmpegLog.split(/[\s:]+/); // split by colon and/or space
    if (!log || log.length == 0) {
      continue;
    }
    // video stream
    if (log[0] === 'Stream' && log.includes('Video')) {
      const index = log.findIndex(l => l === 'Video');
      if (index !== -1 && log[index + 1]) {
        codec = log[index + 1].toString().substring(0, log[index + 1].length - 1);
      }
    }
    if (log[0] == 'Stream' && log.includes('Audio')) {
      // matches the pattern - '{number} kb/s'
      const bitrateMatch = ffmpegLog.match(/\d+\s*kb\/s/);
      const bitrateString = bitrateMatch && bitrateMatch[0].split(' ')[0];
      if (bitrateString && !Number.isNaN(bitrateString)) {
        bitrate = Number(bitrateString);
      }
      isAudioPresent = true;
    }
  }
  return {
    isAudioPresent,
    audioBitrate: bitrate,
    codec
  };
}

// In Roimatic App, we prefix all env variables with 'VUE_APP', which is not the case with server side node app.
// Hence the env variable name for both will be different
export const getAssetUrl = () => {
  return isNode ? process.env.CB_ASSETS_URL : process.env.VUE_APP_CB_ASSETS_URL;
}

export const getPythonApiUrl = () => {
  return isNode ? process.env.PYTHON_API_URL : process.env.VUE_APP_PYTHON_API_URL;
}

export const getInpaintApiUrl = () => {
  return isNode ? process.env.INPAINT_API_URL : process.env.VUE_APP_INPAINT_API_URL;
}

// In case of browser download, video object element is set to image element to capture individual video frames.
// After download, we need to point the video object back to a video element.
// Creating a backup of the video element, so that we dont have to create it again
export const backupVideoElements = (adLayers) => {
  if (!isNode) {
    const videoLayers = adLayers.filter(l => l.type === layerTypes.video);
    videoLayers.forEach((videoLayer) => {
      const videoElement = videoLayer.fabricObject && videoLayer.fabricObject.getElement();
      videoLayer.videoElementBackup = videoElement;
    });
  }
};

export const getVideoStream = async (videoSrc, ffmpeg) => {
  let probeData = {};
  await new Promise((resolve, reject) => {
    ffmpeg.ffprobe(videoSrc, (err, metadata) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        reject();
      }
      probeData = metadata;
      resolve();
    });
  });
  return probeData.streams.find(stream => stream.codec_type.toLowerCase() === 'video');
}

export const replaceWithCDN = (srcUrl) => {
  const s3Url = `${process.env.VUE_APP_STORAGE_URL}/AllFiles/Uploads/`;
  const cdnUrl = process.env.VUE_APP_CB_GALLERY_URL;

  if (srcUrl && srcUrl.startsWith(s3Url) && cdnUrl) {
    const replacedUrl = srcUrl.replace(s3Url, cdnUrl);
    return replacedUrl;
  }

  return srcUrl;
};