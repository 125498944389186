const Audience = () => import(/* webpackChunkName: "audience" */ 'Features/audience/Main');

export default {
  name: 'audience',
  path: 'audience',
  component: Audience,
  meta: {
    requiresAuth: true,
    title: 'message.audience',
    breadcrumb: 'message.audience'
  }
}
