const ReviewManagement = () => import(/* webpackChunkName: "reviewManagement" */ 'Features/reviewManagement/Main');

export default {
  name: 'reviewManagement',
  path: 'reviewManagement',
  component: ReviewManagement,
  meta: {
    requiresAuth: true,
    title: 'Review Management',
    breadcrumb: 'Review Management'
  }
}