<template>
  <div class="app-card-title" :class="[customClasses]">
    <slot />
  </div>
</template>

<script>
export default {
  props: [
    'heading',
    'closeable',
    'reloadable',
    'fullScreen',
    'withTabs',
    'tabs',
    'onCollapse',
    'onReload',
    'onClose',
    'onChangeTabCallback',
    'customClasses'
  ],
  data() {
    return {
      activeTab: 0
    };
  },
  methods: {
    onChangeTab(value) {
      this.activeTab = value;
      this.$emit('onChangeTabCallback', value);
    }
  }
};
</script>
