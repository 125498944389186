const ProductInsights = () => import(/* webpackChunkName: "productInsights" */'./Main');

export default {
  name: 'productInsights',
  path: 'productInsights',
  component: ProductInsights,
  meta: {
    requiresAuth: true,
    title: 'message.productInsights.productInsights',
    breadcrumb: 'message.productInsights.productInsights',
    showDateRangeFilter: true
  }
}
