/**
 * Feature Settings Module
 */

import { SettingsType } from '../../../constants/SettingsType';
import {
  categorySettingsData
} from './data';
import { menuItemKeys } from '../sidebar/data';

const state = {
  disableChatSupport: true,
  businessProfileFacebookFeatureFlags: null,
  businessProfileSettings: {}
}

// getters
const getters = {
  featureSettings: (state, getters) => categorySettingsData[getters.roimaticCategory].featureSettings,
  supportedMenus: (state, getters) => {
    if(state.businessProfileFacebookFeatureFlags &&
       state.businessProfileFacebookFeatureFlags.IsHubReviewManagementEnabled) {
      return categorySettingsData[getters.roimaticCategory].supportedMenus;
    }
    return categorySettingsData[getters.roimaticCategory].supportedMenus.filter(o => o !== menuItemKeys.REVIEW_MANAGEMENT);
  },
  campaignLaunchSteps: (state, getters)=>categorySettingsData[getters.roimaticCategory].campaignLaunchSteps,
  creativesOrder: (state, getters)=>categorySettingsData[getters.roimaticCategory].creativesOrder,
  storeProfileSettings: (state, getters) => categorySettingsData[getters.roimaticCategory].storeProfileSettings,
  disableChatSupport: state => state.disableChatSupport,
  businessProfileFacebookFeatureFlags: state => state.businessProfileFacebookFeatureFlags,
  getBusinessProfileSettings: state => (key, type = SettingsType.BOOLEAN) => {
    let value = false;
    let keyModules = key.split('.');
    key = keyModules[keyModules.length - 1];
    key = key.charAt(0).toLowerCase() + key.slice(1);
    if (state.businessProfileSettings) {
      const hasKey = state.businessProfileSettings.hasOwnProperty(key);
      switch (type) {
        case SettingsType.TEXT:
          value = hasKey ? state.businessProfileSettings[key] : '';
          break;
        case SettingsType.JOBJECT:
          value = hasKey ? JSON.parse(state.businessProfileSettings[key]) : '{}';
          break;
        case SettingsType.JARRAY:
          value = hasKey ? JSON.parse(state.businessProfileSettings[key]) : '[]';
          break;
        default:
          value = hasKey && (state.businessProfileSettings[key].toLowerCase() === 'true');
      }
    }
    return value;
  },
  getStoreSettingsKeyName: state => (key) => {
    let keyModules = key.split('.');
    key = keyModules[keyModules.length - 1];
    key = key.charAt(0).toLowerCase() + key.slice(1);
    return key;
  },
  getSettingsValue: state => (settings, key) => {
    if (settings && settings.length > 0) {
      let setting = settings.find(s => s.key.toLowerCase().startsWith(key.toLowerCase()));
      return setting.hasOwnProperty('value') ? setting.value : '';
    }
    return '';
  }
}

//actions
const actions = {
  setBusinessProfileFacebookFeatureFlags(context, payload) {
    let facebookFeatureFlags = payload;
    if(facebookFeatureFlags) {
      if(facebookFeatureFlags.IsHubReviewManagementEnabled &&
         typeof(facebookFeatureFlags.IsHubReviewManagementEnabled) === 'string') {
        if(facebookFeatureFlags.IsHubReviewManagementEnabled.toLowerCase() === 'true') {
          facebookFeatureFlags.IsHubReviewManagementEnabled = true;
        }
        else {
          facebookFeatureFlags.IsHubReviewManagementEnabled = false;
        }
      }

      // can add same logic here for other property/flag
    }
    context.commit('setBusinessProfileFacebookFeatureFlags', facebookFeatureFlags);
  },
  setBusinessProfileSettings(context, payload) {
    let settings = {};
    payload.forEach(setting => {
      settings[context.getters.getStoreSettingsKeyName(setting.key)] = context.getters.getSettingsValue(payload, setting.key);
    });
    context.commit('setBusinessProfileSettings', settings);
  }
}

// mutations
const mutations = {
  setBusinessProfileFacebookFeatureFlags(state, payload) {
    state.businessProfileFacebookFeatureFlags = payload;
  },
  setBusinessProfileSettings (state, payload) {
    state.businessProfileSettings = payload;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}