export const black = {
  hex: '#000000',
  rgba: {
    r: 0,
    g: 0,
    b: 0,
    a: 1
  }
}
export const white = {
  hex: '#ffffff',
  rgba: {
    r: 255,
    g: 255,
    b: 255,
    a: 1
  }
}
export const blue = {
  hex: '#0000ff',
  rgba: {
    r: 0,
    g: 0,
    b: 255,
    a: 1
  }
}
export const red = {
  hex: '#ff0000',
  rgba: {
    r: 255,
    g: 0,
    b: 0,
    a: 1
  }
}
export const officeGreen = {
  hex: '#008000',
  rgba: {
    r: 0,
    g: 128,
    b: 0,
    a: 1
  }
}
export const orchid = {
  hex: '#DA70D6',
  rgba: {
    r: 218,
    g: 112,
    b: 214,
    a: 1
  }
}
export const transparent = {
  hex: '#000000',
  rgba: {
    r: 0,
    g: 0,
    b: 0,
    a: 0
  }
}

export const alignGuidesColor = {
  hex: '#fb8c00',
  rgba: {
    r: 251,
    g: 140,
    b: 0,
    a: 0
  }
}