const richMediaAdPreview = () => import('Features/richMediaAdPreview/Main');

export default {
  name: 'richMediaAdPreview',
  path: 'richMediaAdPreview/:id',
  component: richMediaAdPreview,
  meta: {
    requiresAuth: false,
    title: 'message.richMediaAdPreview.adPreview',
    breadcrumb: 'message.richMediaAdPreview.adPreview'
  }
}
