const Home = () => import(/* webpackChunkName: "homePage" */ 'Features/home/Main')

const homeRoute =
{
  name: 'home',
  path: '/home',
  component: Home,
  meta: {
    title: 'message.home'
  }

};

export const HomeRoute = homeRoute;