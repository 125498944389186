import deltaxTheme from 'Themes/defaultTheme';

// languages
export const languages = [
  {
    name: 'English',
    icon: 'en',
    locale: 'en'
  },
  {
    name: 'French',
    icon: 'fr',
    locale: 'fr'
  },
  {
    name: 'Hebrew',
    icon: 'he',
    locale: 'he'
  },
  {
    name: 'Russian',
    icon: 'ru',
    locale: 'ru'
  },
  {
    name: 'Arabic',
    icon: 'ar',
    locale: 'ar'
  },
  {
    name: 'Chinese',
    icon: 'cn',
    locale: 'cn'
  },
  {
    name: 'German',
    icon: 'ge',
    locale: 'ge'
  },
  {
    name: 'Spanish',
    icon: 'es',
    locale: 'sp'
  },
  {
    name: 'Japanese',
    icon: 'ja',
    locale: 'ja'
  },
  {
    name: 'Korean',
    icon: 'ko',
    locale: 'ko'
  },
  {
    name: 'Italian',
    icon: 'it',
    locale: 'it'
  },
  {
    name: 'Hungarian',
    icon: 'hu',
    locale: 'hu'
  }
]
// sidebar filters
export const sidebarFilters = [
  {
    id: 1,
    class: 'sidebar-overlay-dark',
    name: 'message.dark'
  }
]

// router animations
export const routerAnimations = [
  'fadeIn',
  'slideInUp',
  'slideInRight',
  'bounce',
  'lightSpeedIn',
  'pulse'
]

// themes
export const themes = [
  {
    id: 1,
    bgColor: 'bg-success',
    theme: deltaxTheme
  }
]

// header filter
export const headerFilters = [
  {
    id: 1,
    class: 'primary'
  },
  {
    id: 2,
    class: 'light'
  },
  {
    id: 3,
    class: 'warning'
  },
  {
    id: 4,
    class: 'success'
  },
  {
    id: 5,
    class: 'error'
  },
  {
    id: 6,
    class: 'info'
  }
]