/**
 * Payment Module
 */

const state = {
  paymentOptions: [],
  isWalletLoading: true,
  isPaymentOptionsLoading: true,
  wallet: null,
  transactions: [],
  isInvoicesLoading: true,
  isTransactionsLoading: true,
  countryPaymentGateway: {}
}

// getters
const getters = {
  paymentOptions: state => state.paymentOptions,
  isWalletLoading: state => state.isWalletLoading,
  isPaymentOptionsLoading: state => state.isPaymentOptionsLoading,
  wallet: state => state.wallet,
  transactions: state => state.transactions,
  isInvoicesLoading: state => state.isInvoicesLoading,
  isTransactionsLoading: state => state.isTransactionsLoading,
  countryPaymentGateway: state => state.countryPaymentGateway
}

// actions
const actions = {
  setIsLoading(context, payload) {
    context.commit('setIsLoading', payload);
  },
  setWallet(context, payload) {
    context.commit('setWallet', payload);
  },
  setPaymentOptions(context, payload) {
    context.commit('setPaymentOptions', payload);
  },
  setTransactions(context, payload) {
    context.commit('setTransactions', payload);
  },
  setCountryPaymentGateway(context, payload) {
    context.commit('setCountryPaymentGateway', payload);
  }
}

// mutations
const mutations = {
  setPaymentOptions(state, paymentOptions) {
    state.paymentOptions = paymentOptions;
  },
  setIsLoading(state, payload) {
    const { isPaymentOptionsLoading, isWalletLoading, isInvoicesLoading, isTransactionsLoading } = payload;
    state.isPaymentOptionsLoading = isPaymentOptionsLoading === undefined ? state.isPaymentOptionsLoading : isPaymentOptionsLoading;
    state.isWalletLoading = isWalletLoading === undefined ? state.isWalletLoading : isWalletLoading;
    state.isInvoicesLoading = isInvoicesLoading === undefined ? state.isWalletLoading : isInvoicesLoading;
    state.isTransactionsLoading = isTransactionsLoading === undefined ? state.isTransactionsLoading : isTransactionsLoading;
  },
  setWallet(state, wallet) {
    state.wallet = wallet;
  },
  setTransactions(state, transactions) {
    state.transactions = transactions;
  },
  setCountryPaymentGateway(state, paymentGateway) {
    state.countryPaymentGateway = paymentGateway
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}