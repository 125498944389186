import axios from 'axios';
import { stringify } from 'qs';
import { handleError } from './apiErrorHandler'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  paramsSerializer: function (params) {
    return stringify(params, { arrayFormat: 'indices', allowDots: true })
  }
});
axiosInstance.interceptors.request.use(function (config) {
  var token = localStorage.getItem('accessToken')
  if (token)
    config.headers.Authorization = `Bearer ${token}`;
  return config;
});
axiosInstance.interceptors.response.use(function (response) {
  return response;
}, handleError);
export default axiosInstance