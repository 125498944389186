const Addons = () => import(/* webpackChunkName: "addons" */'./Main');
const Packs = () => import(/* webpackChunkName: "addons" */'./components/AddonPack');
const addonsRoute =  {
  name: 'addons',
  path: 'addons',
  component: Addons,
  meta: {
    requiresAuth: true,
    title: 'message.addons.addons',
    breadcrumb: 'message.addons.addons',
    showDateRangeFilter: false
  }
}

const packsRoute =  {
  name: 'packs',
  path: 'addons/categories/:categoryId/packs/:packId',
  component: Packs,
  meta: {
    requiresAuth: true,
    title: 'message.addons.addons',
    breadcrumb: 'message.addons.addons',
    showDateRangeFilter: false,
    routeName: 'addons',
    isBpLevelRoute: true
  }
}

export const AddonsRoute = addonsRoute;
export const PacksRoute = packsRoute;

