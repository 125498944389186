import { OnboardingRoute } from './constants'

const OnboardingLayout = () => import(/* webpackChunkName: "onboardingLayout" */ 'Layouts/OnboardingLayout');
const StoreSelection = () => import(/* webpackChunkName: "storeSelection" */ 'Features/onboarding/components/StoreSelection');
const SignupUser = () => import(/* webpackChunkName: "signupUser" */ 'Features/onboarding/components/SignupUser');
const LinkStore = () => import(/* webpackChunkName: "linkStore" */ 'Features/onboarding/components/LinkStore');
const LinkInstructions = () => import(/* webpackChunkName: "linkInstructions" */ 'Features/onboarding/components/LinkInstructions');
const StoreDetails = () => import(/* webpackChunkName: "storeDetails" */ 'Features/onboarding/components/StoreDetails');
const Assets = () => import(/* webpackChunkName: "assets" */ 'Features/onboarding/components/Assets');
const KYCVerification = () => import(/* webpackChunkName: "kycVerification" */ 'Features/onboarding/components/KYCVerification');
const OtherStore = () => import(/* webpackChunkName: "otherStore" */ 'Features/onboarding/components/OtherStore')
const StoreConfirmation = () => import(/* webpackChunkName: "storeConfirmation" */ 'Features/onboarding/components/StoreConfirmation')

export default [
  {
    name: OnboardingRoute.Signup.name,
    path: OnboardingRoute.Signup.path,
    redirect: OnboardingRoute.StoreSelection.path,
    component: OnboardingLayout,
    children: [
      {
        name: OnboardingRoute.StoreSelection.name,
        path: OnboardingRoute.StoreSelection.path,
        component: StoreSelection,
        meta: {
          requiresAuth: false
        }
      },
      {
        name: OnboardingRoute.SignupUser.name,
        path: OnboardingRoute.SignupUser.path,
        component: SignupUser,
        meta: {
          requiresAuth: false
        }
      },
      {
        name: OnboardingRoute.LinkStore.name,
        path: OnboardingRoute.LinkStore.path,
        component: LinkStore,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: OnboardingRoute.LinkInstructions.name,
        path: OnboardingRoute.LinkInstructions.path,
        component: LinkInstructions,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: OnboardingRoute.OtherStore.name,
        path: OnboardingRoute.OtherStore.path,
        component: OtherStore,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: OnboardingRoute.StoreDetails.name,
        path: OnboardingRoute.StoreDetails.path,
        component: StoreDetails,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: OnboardingRoute.Assets.name,
        path: OnboardingRoute.Assets.path,
        component: Assets,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: OnboardingRoute.KYCVerification.name,
        path: OnboardingRoute.KYCVerification.path,
        component: KYCVerification,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: OnboardingRoute.StoreConfirmation.name,
        path: OnboardingRoute.StoreConfirmation.path,
        component: StoreConfirmation, meta: {
          requiresAuth: true
        }
      },
      {
        name: OnboardingRoute.SetupCreativeBuilder.name,
        path: OnboardingRoute.SetupCreativeBuilder.path,
        component: StoreDetails,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: OnboardingRoute.SetupRichMediaAdBuilder.name,
        path: OnboardingRoute.SetupRichMediaAdBuilder.path,
        component: StoreDetails,
        meta: {
          requiresAuth: true
        }
      }
    ],
    meta: {
      title: 'message.signUp'
    }
  }
]