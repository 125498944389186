import { i18n } from '@/lang/'

export const Languages = [
  {
    id: 1,
    name: i18n.tc('message.userBlockMessages.english'),
    code: 'en'
  },
  {
    id: 2,
    name: i18n.tc('message.userBlockMessages.portuguese'),
    code: 'pt_PT'
  },
  {
    id: 3,
    name: i18n.tc('message.userBlockMessages.italian'),
    code: 'it'
  },
  {
    id: 4,
    name: i18n.tc('message.userBlockMessages.hindi'),
    code: 'hi'
  }
]
