<template>
  <v-container fluid grid-list-xl class="pb-1">
    <slot />
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: this.$breadcrumbs.map(breadcrumb => ({
        text: this.$t(breadcrumb.meta.breadcrumb)
      }))
    };
  },
  computed: {
    // computed property to get page breadcrumbs
    pageTitle() {
      return this.$breadcrumbs[0].meta.title;
    }
  }
};
</script>
<style scoped>
.date-range-picker {
  max-height:30px;

}
</style>
