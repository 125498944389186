const PageNotFound = () => import(/* webpackChunkName: "pageNotFound" */ 'Features/invalidRoute/PageNotFound');

export const PageNotFoundRoute = {
  name: 'pageNotFound',
  path: '*',
  component: PageNotFound,
  meta: {
    title: 'message.pageNotFound',
    requiresAuth: false
  }
}