import moment from 'moment'

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}
/**
 * Function to return currenr app layout
 */
export function getCurrentAppLayout(router) {
  let location = router.history.current.fullPath;
  let path = location.split('/')
  return path[1];
}

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
  }
  throw new Error('Bad Hex');
}

export function isJson(string) {
  try {
    if (!string) return false;
    JSON.parse(string);
  }
  catch (error) {
    return false;
  }
  return true;
}

export function isObjectNullOrEmpty(object) {
  if ((object == null) || (typeof object !== 'object')) {
    return true;
  }
  for (var property in object) {
    if (Object.prototype.hasOwnProperty.call(object, property)) {
      return false;
    }
  }
  return true;
}

/**
 * Round number to nearest 10 ceiling
 */
export function roundToNearestTenCeiling(n) {
  return Math.ceil(n / 10) * 10;
}

/**Get the date formatted in the format 'MMMM Do YYYY'*/
export function formatDate(date){
  return moment(date).format('MMMM Do YYYY');
}
