const CampaignLaunch = () => import(/* webpackChunkName: "campaignLaunch" */ 'Features/campaignLaunch/Main');
// const CampaignLaunchVertical = () => import(/* webpackChunkName: "campaignLaunchVertical" */ 'Features/campaignLaunch/CampaignLaunchVertical');

const campaignLaunchRoute =  {
  name: 'campaignLaunch',
  path: 'campaignLaunch',
  component: CampaignLaunch,
  meta: {
    requiresAuth: true,
    title: 'message.campaignLaunchHeader',
    breadcrumb: 'message.campaignLaunchHeader'
  }
}

export default [
  campaignLaunchRoute
  // {
  //   name: 'campaignLaunchVertical',
  //   path: 'campaignLaunchVertical',
  //   component: CampaignLaunchVertical,
  //   meta: {
  //     requiresAuth: true,
  //     title: 'message.campaignLaunchHeader',
  //     breadcrumb: 'message.campaignLaunchHeader'
  //   }
  // }
]

export const CampaignLaunchRoute = campaignLaunchRoute;